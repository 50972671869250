import { Timeseries } from "api/data";
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useEffect, useState } from "react";

export const RawChart = ({
  timeseries,
  colors,
}: {
  timeseries: Timeseries | null;
  colors: string[];
}) => {
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    if (!timeseries) return;

    setData(
      timeseries.values.map((v) => ({
        time: +new Date(v.time),
        ...v,
      })),
    );
  }, [timeseries]);

  if (!timeseries) {
    return (
      <p className="text-space70 text-caption">
        No data for the selected timeframe
      </p>
    );
  }

  if (!data.length) {
    return <p className="text-space70 text-caption">Loading...</p>;
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={250}>
        <LineChart
          data={data}
          margin={{ top: 0, right: 0, left: -25, bottom: 0 }}
        >
          <Tooltip />
          <XAxis 
            dataKey="time" 
            stroke="#b4b4b4"
            tick={{ fontSize: 10 }}
            interval="preserveStartEnd"
            tickFormatter={(dateTime) => {
              return new Date(dateTime).toLocaleTimeString([], {
                hour: "numeric",
                minute: "numeric",
              });
            }}
          />
          <YAxis stroke="#b4b4b4" />
            
          {timeseries.types.map((type, index) => (
            <Line
              key={index}
              type="monotone"
              dataKey={type}
              stroke={colors[index]}
              dot={false}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
      <div className="flex justify-center items-center gap-1">
        {timeseries.types.map((type, index) => (
          <div
            key={index}
            className="px-1 py-2 text-caption text-space50 border-t-4"
            style={{ borderColor: colors[index] }}
          >
            {type} ({timeseries.units[index]})
          </div>
        ))}
      </div>
    </>
  );
};
