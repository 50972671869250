import { ReactComponent as DeleteIcon } from "images/icons/delete.svg";

interface DeleteCertificateModalProps {
    certId: string | null;
    onClose: () => void;
    onDelete: (certId: string) => void;
    placeType: string;
    siteId: string;
    thingId: string;
  }
  
  export const DeleteCertificateModal: React.FC<DeleteCertificateModalProps> = ({
    certId,
    onClose,
    onDelete,
    placeType,
    siteId,
    thingId,
  }) => (
    <div 
      className={`fixed inset-0 bg-black/50 flex items-center justify-center z-50 ${certId ? '' : 'hidden'}`}
      onClick={onClose}
    >
      <div 
        className="bg-white rounded-lg p-6 max-w-lg w-full mx-4 shadow-xl"
        onClick={e => e.stopPropagation()}
      >
        <h2 className="text-2xl text-gray-900 font-medium mb-4">Delete certificate?</h2>
        
        <div className="space-y-4">
          <p className="font-mono text-gray-600 break-all">
            {certId}
          </p>
          
          <p className="text-gray-700">
            Warning text about generating a new certificate to replace the old one
          </p>
          
          <p className="text-gray-600">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
          </p>
        </div>
        
        <div className="mt-6 flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800"
          >
            Cancel
          </button>
          
          <button
            onClick={() => certId && onDelete(certId)}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 flex items-center gap-2"
          >
            Delete
            <DeleteIcon/>
          </button>
        </div>
      </div>
    </div>
  );