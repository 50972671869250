import {
  DatapointMap,
  convertToTimeseries,
  datapointsToMap,
  generateIdealBinSize,
  useDataApi,
} from "api/data";
import { usePlacesApi } from "api/ingestion/places";
import { useSimulationsApi } from "api/ingestion/simulations";
import { useThingsApi } from "api/ingestion/things.ts";
import {
  DeviceDetail,
  EnergyCard,
  NetworkMap,
  SkyportsChargerStatus,
} from "components";
import { HorizontalTimeseriesChart } from "components/charts/horizontalTimeSeries.tsx";
import {
  SelectedDeviceProvider,
  useSelectedDevice,
} from "context/SelectedDeviceContext.tsx";
import {
  SelectedSimulationProvider,
  SelectedSimulationSelector,
  useSelectedSimulation,
} from "context/SelectedSimulationContext.tsx";
import {
  SelectedTimeRangeProvider,
  TimeRangeSelector,
  useSelectedTimeRange,
} from "context/SelectedTimeRangeContext.tsx";

import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import { PlaceAlerts } from "../../components/alerts/PlaceAlerts";
import { useAuth } from "../../context/AuthContext.tsx";
import { EditFleetModal } from "./EditFleetModal";
import AddDeviceModal from "../devices/AddDeviceModal";

const Tile = ({ className = "", children }) => {
  return (
    <div
      className={`bg-white rounded-md shadow border border-gray90 ${className}`}
    >
      {children}
    </div>
  );
};

const DeviceDetailWrapper = () => {
  const { selectedDevice, setSelectedDevice } = useSelectedDevice();
  return (
    <DeviceDetail
      selectedDevice={selectedDevice}
      setSelectedDevice={setSelectedDevice}
      placetype="fleet"
    />
  );
};

const PageContent = ({ fleetId }) => {
  const [fleet, setFleet] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deviceTypes, setDeviceTypes] = useState<string[]>([]);

  const { user, permissions } = useAuth();
  const { editFleet, getFleetDetail, addFleet, deleteFleet } = usePlacesApi();
  const { getFleetAggregateReport, getTimeseriesForFleet, getSummaryForFleet } =
    useDataApi();
  const { getThingsFromFleet, getThingTypes } = useThingsApi();

  const { start, end } = useSelectedTimeRange();
  const { simulationId, simulation } = useSelectedSimulation();
  const navigate = useNavigate();

  const [summaryStats, setSummaryStats] = useState<DatapointMap>({});
  const [batteryDevices, setBatteryDevices] = useState([]);
  const [isDownloadingAggregateData, setIsDownloadingAggregateData] =
    useState(false);
  const [allBatteryTimeseries, setAllBatteryTimeseries] = useState(null);

  useEffect(() => {
    if (!user) return;

    // Reset all timeseries
    if (simulationId === null) {
      setAllBatteryTimeseries(undefined);
    }

    Promise.all([
      getFleetDetail(fleetId),
      getThingTypes()
    ]).then(([fleetData, types]) => {
      setFleet(fleetData);
      setDeviceTypes(types);
    }).catch(console.error);

    const [binValue, binUnit] = generateIdealBinSize(start, end);
    getTimeseriesForFleet(fleetId, start, end, binUnit, binValue, simulationId)
      .then(convertToTimeseries)
      .then(setAllBatteryTimeseries)
      .catch(console.error);

    getThingsFromFleet(fleetId)
      .then((devices) => {
        const batteries = devices.filter(
          (device) => device?.thingType === "Battery",
        );
        setBatteryDevices(batteries);
      })
      .catch((err) => console.error(err));

    getSummaryForFleet(fleetId, start, end, simulationId)
      .then(datapointsToMap)
      .then(setSummaryStats)
      .catch(console.error);
  }, [start, end, fleetId, simulationId]);

  const energyCardStats = [
    {
      value: summaryStats.fwd?.value?.toFixed(0) ?? null,
      units: "kWh",
      label: "Drawn from Grid",
      trend: 0,
    },
    {
      value: null,
      units: "kWh",
      label: "Total Battery Capacity",
      trend: 0,
    },
    {
      value: summaryStats.stored?.value ?? null,
      units: "kWh",
      label: "Currently Stored",
      trend: 0,
    },
    {
      value: batteryDevices.length,
      units: "",
      label: "Number of Batteries",
      trend: 0,
    },
  ];

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleDeviceAdded = (placeType: string, placeId: string, deviceData: any) => {
    return addDevice(placeType, placeId, deviceData).then((result) => {
      if (result.success) {
        getThingsFromFleet(fleetId).then((devices) => {
          let groupedDevices = devices.reduce((acc, device) => {
            acc[device.thingType] = (acc[device.thingType] ?? []).concat(device);
            return acc;
          }, {});
          setBatteryDevices(groupedDevices.Battery ?? []);
          setChargerDevices(groupedDevices.Charger ?? []);
          setMeterDevices(groupedDevices.Meter ?? []);
        });
        return { success: true };
      } else {
        return { success: false, error: result.error };
      }
    });
  };

  const handleDownloadAggregateReport = async () => {
    setIsDownloadingAggregateData(true);
    try {
      await getFleetAggregateReport(fleetId, start, end, "h", 1, simulationId);
    } catch (error) {
      alert(`Download failed: ${error}`);
      console.error("Download failed:", error);
    } finally {
      setIsDownloadingAggregateData(false);
    }
  };

  const handleSaveFleet = async (formData) => {
    const result = await editFleet(fleetId, formData);
    if (result.success) {
      setFleet({ ...fleet, ...formData });
      return true;
    } else {
      console.error("Failed to update fleet:", result.error);
      return false;
    }
  };

  const handleDeleteFleet = async () => {
    try {
      const result = await deleteFleet(fleetId);
      if (result.success) {
        navigate("/fleets");
      } else {
        console.error("Failed to delete fleet:", result.error);
        alert("Failed to delete fleet. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting fleet:", error);
      alert("An error occurred while deleting the fleet. Please try again.");
    }
  };

  const renderActionButtons = () => {
    if (permissions.includes("write:ingest_things")) {
      return (
        <div className="flex items-center gap-2">
          <button
            onClick={() => setIsEditModalOpen(true)}
            className="px-3.5 py-2 rounded-full justify-end items-center gap-1 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue90 focus:ring-blue90 flex border border-space80 hover:bg-gray95 text-space70"
          >
            <div className="text-xs">
              <FaEdit />
            </div>
            <div className="text-xs font-medium leading-[14px]">Edit Fleet</div>
          </button>
          <button
            onClick={handleOpenModal}
            className="px-3.5 py-2 rounded-full justify-end items-center gap-1 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue90 focus:ring-blue90 flex border border-space80 hover:bg-gray95 text-space70"
          >
            <div className="text-xs font-medium leading-[14px]">+ Add Device</div>
          </button>
        </div>
      );
    }
    return null;
  };

  if (!fleet) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex h-full">
      <div className="grow h-screen overflow-y-scroll">
        <div className="flex pt-4 pb-3 justify-between">
          <div className="text-heading1 text-space50 flex items-center">
            <div className="flex items-center">
              <NavLink to="/fleets">Fleets</NavLink>
              <span className="mx-1">/</span>
              <span>{fleet.fleetName}</span>
            </div>
            <div className="ml-4">{renderActionButtons()}</div>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={handleDownloadAggregateReport}
              disabled={isDownloadingAggregateData}
              className="px-3.5 py-2 rounded-full justify-end items-center gap-1 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue90 focus:ring-blue90 flex border border-space80 hover:bg-gray95 text-space70"
            >
              <div className="text-xs font-medium leading-[14px]">
                {isDownloadingAggregateData
                  ? "Downloading..."
                  : "Download Aggregate Report"}
              </div>
            </button>
            <SelectedSimulationSelector />
            <TimeRangeSelector />
          </div>
        </div>
        <hr />
        <div className="my-4">
          <h2 className="text-xl font-semibold mb-2">{fleet.fleetName}</h2>
          {fleet.attributes && Object.keys(fleet.attributes).length > 0 ? (
            <div className="flex flex-wrap gap-2">
              {Object.entries(fleet.attributes).map(([key, value]) => (
                <span
                  key={key}
                  className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                >
                  {key}: {value}
                </span>
              ))}
            </div>
          ) : (
            <p className="text-sm text-gray-500">No attributes defined</p>
          )}
        </div>

        {simulationId !== null && (
          <div className="my-4 bg-blue90 p-4 rounded-md">
            <p className="font-bold">Current Simulation: {simulationId}</p>
            <p>
              {simulation.startDate.toLocaleString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
              {" - "}
              {simulation.endDate?.toLocaleString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
            </p>
          </div>
        )}

        <div className="flex space-x-4 mb-4 h-[300px]">
          <Tile className="w-1/3 p-4">
            <PlaceAlerts
              placeType={"fleet"}
              placeId={fleetId}
              start={start}
              end={end}
              simulationId={simulationId}
            />
          </Tile>
          <Tile className="w-2/3">
            <NetworkMap
              latitude={fleet.latitude || 0}
              longitude={fleet.longitude || 0}
              batteryDevices={batteryDevices}
              chargerDevices={[]}
              meterDevices={[]}
            />
          </Tile>
        </div>

        <div className="flex space-x-4 mb-4">
          <Tile className="w-full">
            <HorizontalTimeseriesChart timeseries={allBatteryTimeseries} />
          </Tile>
        </div>

        <div className="flex space-x-4 mb-4 h-[200px]">
          <Tile className="w-full">
            <EnergyCard energyCardStats={energyCardStats} />
          </Tile>
        </div>

        <div className="flex space-x-4 mb-4">
          <Tile className="w-full">
            <SkyportsChargerStatus
              chargers={[]}
              batteries={batteryDevices}
              meters={[]}
            />
          </Tile>
        </div>
      </div>

      <EditFleetModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        fleet={fleet}
        onSave={handleSaveFleet}
        onDelete={handleDeleteFleet}
      />

      <AddDeviceModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onDeviceAdded={handleDeviceAdded}
        places={[{ id: fleet?.fleetId, name: fleet?.fleetName, type: 'fleet' }]}
        deviceTypes={deviceTypes}
      />    

      <DeviceDetailWrapper />
    </div>
  );
};

const FleetDetail = () => {
  const { fleetId } = useParams();

  const [simulations, setSimulations] = useState<SimulationResponse[]>([]);

  const { getSimulationsForPlace } = useSimulationsApi();

  useEffect(() => {
    getSimulationsForPlace(fleetId, "fleet").then(setSimulations);
  }, [fleetId]);

  return (
    <SelectedTimeRangeProvider>
      <SelectedDeviceProvider>
        <SelectedSimulationProvider simulations={simulations}>
          <PageContent fleetId={fleetId} />
        </SelectedSimulationProvider>
      </SelectedDeviceProvider>
    </SelectedTimeRangeProvider>
  );
};

export default FleetDetail;
