import React, { useState, useEffect } from 'react';
import { FaTrash, FaPlus } from 'react-icons/fa';

export const EditFleetModal = ({ isOpen, onClose, fleet, onSave, onDelete }) => {
  const [formData, setFormData] = useState({
    fleetName: '',
    attributes: {},
  });
  const [newAttribute, setNewAttribute] = useState({ name: '', value: '' });
  const [deletedAttributes, setDeletedAttributes] = useState([]);
  const [errors, setErrors] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (isOpen && fleet) {
      setFormData({
        fleetName: fleet.fleetName,
        attributes: { ...fleet.attributes },
      });
      setDeletedAttributes([]);
      setNewAttribute({ name: '', value: '' });
      setErrors({});
      setIsDeleting(false);
    }
  }, [isOpen, fleet]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAttributeChange = (oldKey, newKey, value) => {
    setFormData(prev => {
      const updatedAttributes = { ...prev.attributes };
      if (oldKey !== newKey) {
        delete updatedAttributes[oldKey];
      }
      updatedAttributes[newKey] = value;
      return { ...prev, attributes: updatedAttributes };
    });
  };

  const handleDeleteAttribute = (key) => {
    setFormData(prev => {
      const updatedAttributes = { ...prev.attributes };
      delete updatedAttributes[key];
      return { ...prev, attributes: updatedAttributes };
    });
    setDeletedAttributes(prev => [...prev, key]);
  };

  const handleAddAttribute = () => {
    if (newAttribute.name && newAttribute.value) {
      setFormData(prev => ({
        ...prev,
        attributes: { ...prev.attributes, [newAttribute.name]: newAttribute.value }
      }));
      setNewAttribute({ name: '', value: '' });
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.fleetName.trim()) {
      newErrors.fleetName = 'Fleet name is required';
    }

    const attributeNames = new Set();
    Object.entries(formData.attributes).forEach(([key, value]) => {
      if (!key.trim()) {
        newErrors.attributes = newErrors.attributes || {};
        newErrors.attributes[key] = 'Attribute name is required';
      } else if (attributeNames.has(key)) {
        newErrors.attributes = newErrors.attributes || {};
        newErrors.attributes[key] = 'Duplicate attribute name';
      } else {
        attributeNames.add(key);
      }

      if (!value.trim()) {
        newErrors.attributes = newErrors.attributes || {};
        newErrors.attributes[key] = (newErrors.attributes[key] || '') + ' Attribute value is required';
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const dataToSend = {
        ...formData,
        attributesToDelete: deletedAttributes
      };
      const success = await onSave(dataToSend);
      if (success) {
        onClose();
      }
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this fleet? This action cannot be undone.')) {
      setIsDeleting(true);
      try {
        await onDelete(fleet.fleetId);
        onClose();
      } catch (error) {
        console.error('Error deleting fleet:', error);
        alert('Failed to delete fleet. Please try again.');
      } finally {
        setIsDeleting(false);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed z-20 inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg w-full max-w-2xl relative z-50 max-h-[90vh] overflow-y-auto">
        <h2 className="text-2xl font-bold mb-6">Edit Fleet</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="fleetName" className="block text-sm font-medium text-gray-700 mb-1">Fleet Name</label>
            <input
              type="text"
              id="fleetName"
              name="fleetName"
              value={formData.fleetName}
              onChange={handleChange}
              className={`w-full border rounded-md px-3 py-2 ${errors.fleetName ? 'border-red-500' : 'border-gray-300'}`}
            />
            {errors.fleetName && <p className="text-red-500 text-xs mt-1">{errors.fleetName}</p>}
          </div>
          
          <div className="mb-4">
            <h3 className="text-lg font-medium mb-2">Attributes</h3>
            <div className="bg-gray-50 p-4 rounded-md">
              {Object.entries(formData.attributes).map(([key, value]) => (
                <div key={key} className="flex space-x-2 mb-2">
                  <input
                    type="text"
                    value={key}
                    onChange={(e) => handleAttributeChange(key, e.target.value, value)}
                    className={`flex-1 border rounded-md px-3 py-2 ${errors.attributes?.[key] ? 'border-red-500' : 'border-gray-300'}`}
                    placeholder="Attribute Name"
                  />
                  <input
                    type="text"
                    value={value}
                    onChange={(e) => handleAttributeChange(key, key, e.target.value)}
                    className={`flex-1 border rounded-md px-3 py-2 ${errors.attributes?.[key] ? 'border-red-500' : 'border-gray-300'}`}
                    placeholder="Attribute Value"
                  />
                  <button
                    type="button"
                    onClick={() => handleDeleteAttribute(key)}
                    className="px-2 py-2 text-gray-500 hover:text-red-500 w-10 h-10 flex items-center justify-center"
                  >
                    <FaTrash />
                  </button>
                </div>
              ))}
              {errors.attributes && Object.entries(errors.attributes).map(([key, error]) => (
                <p key={key} className="text-red-500 text-xs mt-1 mb-2">{`${key}: ${error}`}</p>
              ))}
              <div className="flex space-x-2 mb-2">
                <input
                  type="text"
                  value={newAttribute.name}
                  onChange={(e) => setNewAttribute(prev => ({ ...prev, name: e.target.value }))}
                  className="flex-1 border rounded-md px-3 py-2 border-gray-300"
                  placeholder="New Attribute Name"
                />
                <input
                  type="text"
                  value={newAttribute.value}
                  onChange={(e) => setNewAttribute(prev => ({ ...prev, value: e.target.value }))}
                  className="flex-1 border rounded-md px-3 py-2 border-gray-300"
                  placeholder="New Attribute Value"
                />
                <button
                  type="button"
                  onClick={handleAddAttribute}
                  className="px-2 py-2 bg-gray-200 text-gray-600 rounded-md hover:bg-gray-300 w-10 h-10 flex items-center justify-center"
                >
                  <FaPlus />
                </button>
              </div>
            </div>
          </div>
          
          <div className="mt-8 space-y-4">
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 rounded-md border border-gray-300 text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 rounded-md border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Save Changes
              </button>
            </div>
            
            <div className="pt-4 border-t border-gray-200">
              <button
                type="button"
                onClick={handleDelete}
                disabled={isDeleting}
                className="w-full px-4 py-2 rounded-md border border-red-300 text-red-600 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 flex items-center justify-center"
              >
                <FaTrash className="mr-2" />
                {isDeleting ? 'Deleting...' : 'Delete Fleet'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};