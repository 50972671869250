import { DataResponse, useDataApi } from "api/data";
import { Dayjs } from "dayjs";

import React, { useEffect, useState } from "react";
import { IoMdBatteryCharging } from "react-icons/io";
import { MdOutlinePower } from "react-icons/md";

export const AlertRow = (alert: Alert) => {
  const { thingId, time, type, value } = alert;
  const icon =
    type === "batteryAlarm" ? (
      <IoMdBatteryCharging fontSize={20} />
    ) : (
      // else "stationAlarm"
      <MdOutlinePower fontSize={20} />
    );

  const timestamp = new Date(time).toLocaleString();

  return (
    <div className="p-2 bg-yellow50 rounded justify-between items-start inline-flex text-white text-body">
      <div className="justify-start items-start gap-2 flex">
        <div>{icon}</div>
        <div>{value}</div>
        <div className="opacity-50">{thingId}</div>
      </div>
      <div className="justify-end items-center gap-2 flex">{timestamp}</div>
    </div>
  );
};

export const PlaceAlerts = ({
  placeType,
  placeId,
  start,
  end,
  simulationId,
}: {
  placeType: string;
  placeId: string;
  start: Dayjs;
  end: Dayjs;
  simulationId: string | null;
}) => {
  const { getAlertsForPlace } = useDataApi();
  const [alerts, setAlerts] = useState<Alert[]>([]);

  useEffect(() => {
    getAlertsForPlace(placeType, placeId, start, end, simulationId)
      .then(flattenAlerts)
      .then(setAlerts);
  }, [placeType, placeId, start, end, simulationId]);

  return (
    <div className="overflow-auto h-full">
      <p className="text-space50 text-heading3 mb-4">Alerts</p>
      <div className="flex flex-col gap-2 pt-2">
        {alerts && alerts.length > 0 ? (
          alerts.map((alert, idx) => <AlertRow {...alert} key={idx} />)
        ) : (
          <div className="text-caption text-center text-space70 py-4">
            No alerts for selected timeframe
          </div>
        )}
      </div>
    </div>
  );
};

export type Alert = {
  thingId: string;
  time: string;
  type: string;
  value: string;
  unit: string;
};

const flattenAlerts = (alerts: DataResponse[]): Alert[] => {
  // for each alert, get the time and first dataPoints value
  return alerts
    .map((alert) => {
      const dataPoint = alert.dataPoints[0];

      if (!dataPoint) {
        return null;
      }

      return {
        thingId: alert.groupBy,
        time: alert.time,
        type: dataPoint.type,
        value: JSON.parse(dataPoint.value)[0],
        unit: dataPoint.unit,
      };
    })
    .filter((e) => e !== null);
};
