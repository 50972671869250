import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import {
  InviteUserResponse,
  Role,
  useIdentityApi,
} from "api/ingestion/identity";
import { useAuth } from "context/AuthContext";

import React, { useEffect, useState } from "react";

import {
  EditableTextField,
  PrimaryButton,
  RoleSelector,
  SecondaryButton,
} from "./shared";

type NewUserInformation = {
  inviteeEmail: string;
  inviterName: string;
  roles: string[];
};

const AddForm = ({
  roles,
  name,
  onSave,
}: {
  roles: Role[];
  name: string;
  onSave: (newInfo: NewUserInformation) => void;
}) => {
  const [info, setInfo] = useState<NewUserInformation>({
    inviteeEmail: "",
    inviterName: name,
    roles: [],
  });

  return (
    <>
      <DialogTitle
        as="h3"
        className="text-center text-base font-semibold leading-6 text-gray-900"
      >
        Invite Member
      </DialogTitle>
      <div className="mt-4">
        <div className="flex flex-col gap-2">
          <EditableTextField
            label="Email"
            value={info.inviteeEmail}
            onChange={(value) => setInfo({ ...info, inviteeEmail: value })}
          />
          <RoleSelector
            roles={roles}
            selected={info.roles}
            onChange={(value) => setInfo({ ...info, roles: value })}
          />
        </div>
      </div>
      <PrimaryButton disabled={!info.inviteeEmail} onClick={() => onSave(info)}>
        Invite Member
      </PrimaryButton>
    </>
  );
};

export const AddUserModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const { getRoles, inviteUser } = useIdentityApi();
  const { user } = useAuth();

  const [roles, setRoles] = useState<Role[] | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [inviteResponse, setInviteResponse] =
    useState<InviteUserResponse | null>(null);
  const [inviteeEmail, setInviteeEmail] = useState<string>("");

  useEffect(() => {
    getRoles().then(setRoles);
  }, []);

  const addError = (error: string) => {
    setErrors((prev) => [...prev, error]);
  };

  const handleSave = (newInfo: NewUserInformation) => {
    setInviteeEmail(newInfo.inviteeEmail);
    inviteUser(newInfo.inviteeEmail, newInfo.inviterName, newInfo.roles)
      .then((response) => setInviteResponse(response))
      .catch((error) => addError(error.message));
  };

  const handleClose = () => {
    setErrors([]);
    setInviteResponse(null);
    setInviteeEmail("");
    setOpen(false);
  };

  const closeButton = (
    <SecondaryButton onClick={handleClose}>
      {inviteResponse ? "Close" : "Cancel"}
    </SecondaryButton>
  );

  const content =
    errors.length > 0 ? (
      <div className="flex flex-col gap-4 pt-4">
        <div className="text-center font-semibold text-md text-red40">
          Error
        </div>
        {errors.map((error, index) => (
          <div className="text-center text-sm text-red40" key={index}>
            {error}
          </div>
        ))}
        {closeButton}
      </div>
    ) : inviteResponse ? (
      <>
        <DialogTitle
          as="h3"
          className="text-center text-base font-semibold leading-6 text-gray-900 capitalize"
        >
          {inviteResponse.message}
        </DialogTitle>
        <div className="flex flex-col gap-4 pt-4">
          <div className="text-center text-sm text-space70">
            An invitation email has been sent to {inviteeEmail}. Here's the link
            for convenience:
          </div>
          <div className="text-green40 bg-green90 p-2 px-3 rounded-md break-all">
            {inviteResponse.invitationUrl}
          </div>
          <PrimaryButton
            onClick={() =>
              navigator.clipboard.writeText(inviteResponse.invitationUrl)
            }
          >
            Copy Invitation Link
          </PrimaryButton>
          {closeButton}
        </div>
      </>
    ) : roles ? (
      <>
        <AddForm roles={roles} name={user?.name} onSave={handleSave} />
        {closeButton}
      </>
    ) : (
      <div className="mt-5">
        <div className="text-center text-space50 pt-4">Loading...</div>
        {closeButton}
      </div>
    );

  return (
    <Dialog open={open} onClose={handleClose} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            {content}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
