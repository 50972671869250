import { useAuthFetch } from "context/AuthContext";

import { BASE_URL, GenericAttributes } from "./common";

export type SiteResponse = {
  siteId: string;
  partnerId: string;
  siteName: string;
  address: string;
  latitude: number;
  longitude: number;

  levelTypes: {
    [key: string]: SiteLevelType;
  };
  levelGroups: SiteLevelGroup[];
  attributes: GenericAttributes;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
};

export type FleetResponse = {
  fleetId: string;
  partnerId: string;
  fleetName: string;
  attributes: GenericAttributes;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
};

export type SiteLevelGroup = {
  levelGroupId: string;
  levelGroupName: string;
  levels: string[];
  createdAt: string;
  createdBy: string;
};

export type SiteLevelType = {
  levelTypeName: string;
  levelTypeDescription: string;
  predecessorLevelTypes: string[];
  successorLevelTypes: string[];
};

export type SiteLevel = {
  levelId: string;
  siteId: string;
  partnerId: string;
  path: string;
  levelType: string;
  levelName: string;
  predecessorId?: string; // not set for ROOT
  successorIds: string[];
  levelDescription: string;
  address: string;
  longitude?: number; // not set for ROOT
  latitude?: number; // not set for ROOT
  altitude?: number; // not set for ROOT
  attributes: GenericAttributes;
  createdAt: string;
  createdBy: string;
};

export const usePlacesApi = () => {
  const { authFetch } = useAuthFetch();

  const getSites = async (): Promise<SiteResponse[]> => {
    const url = `${BASE_URL}/sites`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getSiteDetail = async (siteId: string): Promise<SiteResponse> => {
    const url = `${BASE_URL}/site/${siteId}`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };
  
  const getFleets = async (): Promise<FleetResponse[]> => {
    const url = `${BASE_URL}/fleets`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  }

  const getFleetDetail = async (fleetId: string): Promise<FleetResponse> => {
    const url = `${BASE_URL}/fleet/${fleetId}`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  }

  const getSiteLevels = async (siteId: string): Promise<SiteLevel[]> => {
    const url = `${BASE_URL}/site/${siteId}/levels`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getSiteLevelDetails = async (
    siteId: string,
    levelId: string,
  ): Promise<SiteLevel> => {
    const url = `${BASE_URL}/site/${siteId}/level/${levelId}`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const addSite = async (data) => {
    const addSiteUrl = `${BASE_URL}/site`;
    try {
      const response = await authFetch(addSiteUrl, {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
  
      if (response.status === 200) {
        const siteId = await response.text();
        return { 
          success: true, 
          message: "Site created successfully", 
          siteId: siteId,
        };
      } else {
        const errorData = await response.json();
        console.error("Error creating site:", errorData);
        return { success: false, error: errorData };
      }
    } catch (error) {
      console.error("Error creating site:", error);
      return { success: false, error: error.message };
    }
  };

  const addFleet = async (data) => {
    const addFleetUrl = `${BASE_URL}/fleet`;

    try {
      const response = await authFetch(addFleetUrl, {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.status === 200) {
        const fleetId = await response.text();
        return { 
          success: true, 
          message: "Fleet created successfully", 
          fleetId: fleetId,
        };
      } else {
        const errorData = await response.json();
        console.error("Error creating fleet:", errorData);
        return { success: false, error: errorData };
      }
    } catch (error) {
      console.error("Error creating fleet:", error);
      return { success: false, error: error.message };
    }
  };

  const editSite = async (siteId, data) => {
    const editSiteUrl = `${BASE_URL}/site/${siteId}`;
    try {
      const response = await authFetch(editSiteUrl, {
        method: "PATCH",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.status === 200) {
        return { success: true, message: "Site updated successfully" };
      } else {
        const errorData = await response.json();
        console.error("Error updating site:", errorData);
        return { success: false, error: errorData };
      }
    } catch (error) {
      console.error("Error updating site:", error);
      return { success: false, error: error.message };
    }
  };

  const editFleet = async (fleetId, data) => {
    const editFleetUrl = `${BASE_URL}/fleet/${fleetId}`;
    try {
      const response = await authFetch(editFleetUrl, {
        method: "PATCH",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.status === 200) {
        return { success: true, message: "Fleet updated successfully" };
      } else {
        const errorData = await response.json();
        console.error("Error updating fleet:", errorData);
        return { success: false, error: errorData };
      }
    } catch (error) {
      console.error("Error updating fleet:", error);
      return { success: false, error: error.message };
    }
  };

  const deleteSite = async (siteId: string) => {
    const deleteSiteUrl = `${BASE_URL}/site/${siteId}`;
    try {
      const response = await authFetch(deleteSiteUrl, {
        method: "DELETE",
      });

      if (response.status === 200) {
        return { success: true, message: "Site deleted successfully" };
      } else {
        const errorData = await response.json();
        console.error("Error deleting site:", errorData);
        return { success: false, error: errorData };
      }
    } catch (error) {
      console.error("Error deleting site:", error);
      return { success: false, error: error.message };
    }
  };

  const deleteFleet = async (fleetId: string) => {
    const deleteFleetUrl = `${BASE_URL}/fleet/${fleetId}`;
    try {
      const response = await authFetch(deleteFleetUrl, {
        method: "DELETE",
      });

      if (response.status === 200) {
        return { success: true, message: "Fleet deleted successfully" };
      } else {
        const errorData = await response.json();
        console.error("Error deleting fleet:", errorData);
        return { success: false, error: errorData };
      }
    } catch (error) {
      console.error("Error deleting fleet:", error);
      return { success: false, error: error.message };
    }
  };

  return {
    getSites,
    getSiteDetail,
    getFleets,
    getFleetDetail,
    getSiteLevels,
    getSiteLevelDetails,
    addSite,
    addFleet,
    editSite,
    editFleet,
    deleteSite,
    deleteFleet,
  };
};
