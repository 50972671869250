type MapboxConfig = {
  token: string;
};

const env = process.env.REACT_APP_ENV;

const getConfig = (env: string): MapboxConfig => {
  switch (env) {
    case "local":
    case "development":
      return {
        token:
          "pk.eyJ1IjoiYWVyb3Z5LWl0IiwiYSI6ImNtMzIycmE3bzB3aGIyam9kM3RxMWU3eTgifQ.ALZ0-ciZRjnMPxCBuolpMA",
      };

    case "staging":
      return {
        token:
          "pk.eyJ1IjoiYWVyb3Z5LWl0IiwiYSI6ImNtMzIyc3piOTEzc3kyaXB1N3hmMnk3ZGsifQ.2klsBiChYkJ8u-IqfrWomQ",
      };

    default:
      throw new Error(`Unsupported environment: ${env}`);
  }
};

export const mapboxConfig: MapboxConfig = getConfig(env);
