import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { Invite, useIdentityApi } from "api/ingestion/identity";

import { useState } from "react";

import { SecondaryButton } from "./shared";

export const DeleteInviteModal = ({
  invite,
  open,
  setOpen,
}: {
  invite: Invite | null;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const { deleteInvite } = useIdentityApi();
  const [errors, setErrors] = useState<string[]>([]);

  const addError = (error: string) => {
    setErrors((prev) => [...prev, error]);
  };

  const handleDelete = () => {
    deleteInvite(invite.id)
      .then(() => setOpen(false))
      .catch((error) => {
        addError(error.message);
      });
  };

  const handleClose = () => {
    setErrors([]);
    setOpen(false);
  };

  const closeButton = (
    <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
  );

  const content =
    errors.length > 0 ? (
      <div className="flex flex-col gap-4 pt-4">
        <div className="text-center font-semibold text-md text-red40">
          Error
        </div>
        {errors.map((error, index) => (
          <div className="text-center text-sm text-red40" key={index}>
            {error}
          </div>
        ))}
        {closeButton}
      </div>
    ) : invite ? (
      <>
        <div>
          <DialogTitle
            as="h3"
            className="text-center text-base font-semibold leading-6 text-gray-900"
          >
            Delete Invite
          </DialogTitle>
          <div className="text-center text-sm text-gray-500 mt-4">
            Are you sure you want to delete{" "}
            <span className="font-semibold">{invite.invitee.email}</span>?
          </div>
        </div>
        <div className="mt-5">
          <button
            type="button"
            onClick={handleDelete}
            className="inline-flex w-full justify-center rounded-md bg-red50 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-red40 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red50 disabled:bg-gray-400"
          >
            Delete Invite
          </button>
          {closeButton}
        </div>
      </>
    ) : (
      <div className="mt-5">
        <div className="text-center text-space50 pt-4">Loading...</div>
        {closeButton}
      </div>
    );

  return (
    <Dialog open={open} onClose={handleClose} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            {content}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
