import { useState } from "react";

type EntryType = "connection" | "subscribe" | "publish";

interface NewPolicyEntryModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentPolicy: PolicyDocument;
  onUpdate: (policy: PolicyDocument) => Promise<boolean>;
}

interface PolicyDocument {
  Version: string;
  Statement: Array<{
    Effect: string;
    Action: string | string[];
    Resource: string | string[];
    Condition?: Record<string, Record<string, string>>;
  }>;
}

const validateMqttTopic = (
  topic: string,
  isFilter: boolean = false,
): { isValid: boolean; error: string | null } => {
  if (!topic.trim()) {
    return { isValid: false, error: "Topic cannot be empty" };
  }

  if (new TextEncoder().encode(topic).length > 250) {
    return {
      isValid: false,
      error: "Topic exceeds maximum length of 250 bytes",
    };
  }

  const levels = topic.split("/");
  if (levels.length > 128) {
    return { isValid: false, error: "Topic cannot have more than 128 levels" };
  }

  if (!isFilter && (topic.includes("+") || topic.includes("#"))) {
    return {
      isValid: false,
      error: "Publish topics cannot contain wildcards (+ or #)",
    };
  }

  if (isFilter) {
    const hashIndex = topic.indexOf("#");
    if (hashIndex !== -1) {
      if (
        hashIndex !== topic.length - 1 ||
        (hashIndex > 0 && topic[hashIndex - 1] !== "/")
      ) {
        return {
          isValid: false,
          error: "# wildcard must appear at the end and be preceded by /",
        };
      }
    }

    const plusPositions = topic.split("").reduce((acc: number[], char, i) => {
      if (char === "+") acc.push(i);
      return acc;
    }, []);

    for (const pos of plusPositions) {
      const prevChar = pos > 0 ? topic[pos - 1] : "/";
      const nextChar = pos < topic.length - 1 ? topic[pos + 1] : "/";
      if (prevChar !== "/" || (nextChar !== "/" && nextChar !== undefined)) {
        return {
          isValid: false,
          error: "+ wildcard must occupy an entire level",
        };
      }
    }
  }

  if (topic.startsWith("$") && !topic.startsWith("$share/")) {
    return {
      isValid: false,
      error: "Topics starting with $ are reserved for system use",
    };
  }

  return { isValid: true, error: null };
};

export const NewPolicyEntryModal: React.FC<NewPolicyEntryModalProps> = ({
  isOpen,
  onClose,
  currentPolicy,
  onUpdate,
}) => {
  const [entryType, setEntryType] = useState<EntryType>("connection");
  const [value, setValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [validationError, setValidationError] = useState<string | null>(null);

  if (!isOpen) return null;

  const handleInputChange = (inputValue: string) => {
    setValue(inputValue);

    if (entryType === "connection") {
      if (
        inputValue.includes("/") ||
        inputValue.includes("+") ||
        inputValue.includes("#")
      ) {
        setValidationError("Client ID cannot contain /, +, or # characters");
      } else if (inputValue.length > 23) {
        setValidationError("Client ID should not exceed 23 characters");
      } else {
        setValidationError(null);
      }
    } else {
      const isFilter = entryType === "subscribe";
      const { isValid, error } = validateMqttTopic(inputValue, isFilter);
      setValidationError(error);
    }
  };

  const extractArnBase = (
    statement: PolicyDocument["Statement"][0],
  ): string => {
    const resource = Array.isArray(statement.Resource)
      ? statement.Resource[0]
      : statement.Resource;
    const match = resource.match(/arn:aws:iot:([^:]+):([^:]+):/);
    if (match) {
      return `arn:aws:iot:${match[1]}:${match[2]}`;
    }
    throw new Error("Could not determine the correct ARN pattern");
  };

  const handleAdd = async () => {
    if (validationError) {
      return;
    }

    try {
      setIsSubmitting(true);
      setError(null);

      const updatedPolicy = JSON.parse(JSON.stringify(currentPolicy));
      const baseArn = extractArnBase(updatedPolicy.Statement[0]);

      switch (entryType) {
        case "connection": {
          let connectStmt = updatedPolicy.Statement.find(
            (stmt) => stmt.Action === "iot:Connect" && !stmt.Condition,
          );

          if (!connectStmt) {
            connectStmt = {
              Effect: "Allow",
              Action: "iot:Connect",
              Resource: [],
            };
            updatedPolicy.Statement.push(connectStmt);
          }

          const resource = `${baseArn}:client/${value}`;

          if (!Array.isArray(connectStmt.Resource)) {
            connectStmt.Resource = [connectStmt.Resource].filter(Boolean);
          }

          if (!connectStmt.Resource.includes(resource)) {
            connectStmt.Resource.push(resource);
          }
          break;
        }

        case "subscribe": {
          let subscribeStmt = updatedPolicy.Statement.find(
            (stmt) =>
              (Array.isArray(stmt.Action) &&
                stmt.Action.includes("iot:Subscribe")) ||
              stmt.Action === "iot:Subscribe",
          );

          if (!subscribeStmt) {
            subscribeStmt = {
              Effect: "Allow",
              Action: "iot:Subscribe",
              Resource: [],
            };
            updatedPolicy.Statement.push(subscribeStmt);
          }

          const resource = `${baseArn}:topicfilter/${value}`;

          if (!Array.isArray(subscribeStmt.Resource)) {
            subscribeStmt.Resource = [subscribeStmt.Resource].filter(Boolean);
          }

          if (!subscribeStmt.Resource.includes(resource)) {
            subscribeStmt.Resource.push(resource);
          }
          break;
        }

        case "publish": {
          let publishStmt = updatedPolicy.Statement.find(
            (stmt) =>
              (Array.isArray(stmt.Action) &&
                stmt.Action.includes("iot:Publish")) ||
              stmt.Action === "iot:Publish",
          );

          if (!publishStmt) {
            publishStmt = {
              Effect: "Allow",
              Action: ["iot:Publish", "iot:Receive"],
              Resource: [],
            };
            updatedPolicy.Statement.push(publishStmt);
          } else if (!Array.isArray(publishStmt.Action)) {
            publishStmt.Action = [publishStmt.Action, "iot:Receive"];
          } else if (!publishStmt.Action.includes("iot:Receive")) {
            publishStmt.Action.push("iot:Receive");
          }

          const resource = `${baseArn}:topic/${value}`;

          if (!Array.isArray(publishStmt.Resource)) {
            publishStmt.Resource = [publishStmt.Resource].filter(Boolean);
          }

          if (!publishStmt.Resource.includes(resource)) {
            publishStmt.Resource.push(resource);
          }
          break;
        }
      }

      const success = await onUpdate(updatedPolicy);

      if (success) {
        setValue("");
        onClose();
      } else {
        throw new Error("Failed to update policy");
      }
    } catch (error) {
      console.error("Error adding new entry:", error);
      setError("Failed to add new entry. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-[9999]"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg p-6 w-full max-w-lg mx-4"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-xl font-medium mb-6">Add New Entry</h2>

        {error && (
          <div className="mb-4 p-3 bg-red-50 border border-red-200 text-red-700 rounded">
            {error}
          </div>
        )}

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Entry Type
            </label>
            <select
              value={entryType}
              onChange={(e) => {
                setEntryType(e.target.value as EntryType);
                setValue("");
                setValidationError(null);
              }}
              className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="connection">Connect</option>
              <option value="subscribe">Subscribe</option>
              <option value="publish">Publish</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              {entryType === "connection" ? "Client ID" : "Topic"}
            </label>
            <input
              type="text"
              value={value}
              onChange={(e) => handleInputChange(e.target.value)}
              placeholder={
                entryType === "connection"
                  ? "Enter client ID"
                  : entryType === "subscribe"
                    ? "Enter topic filter (can use + and # wildcards)"
                    : "Enter topic path"
              }
              className={`w-full rounded-md border px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                validationError ? "border-red-300" : "border-gray-300"
              }`}
            />
            {validationError && (
              <p className="mt-1 text-sm text-red-600">{validationError}</p>
            )}
          </div>

          <div className="mt-4 p-4 bg-yellow-50 border border-yellow-200 rounded-md">
            <p className="text-sm text-yellow-800">
              Warning: Adding a new policy entry will generate new certificates.
              Make sure to download and update your device certificates after
              making changes.
            </p>
          </div>
        </div>

        <div className="mt-6 flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800"
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            onClick={handleAdd}
            disabled={!value.trim() || isSubmitting || !!validationError}
            className="px-4 py-2 bg-blue-700 text-white rounded hover:bg-blue-800 disabled:opacity-50"
          >
            {isSubmitting ? "Adding..." : "Create Entry +"}
          </button>
        </div>
      </div>
    </div>
  );
};
