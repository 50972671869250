import { useAuth } from "context/AuthContext";

import { FaSignOutAlt } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";

import { ReactComponent as AlertIcon } from "images/icons/alert.svg";
import { ReactComponent as AlertsIcon } from "images/icons/alerts.svg";
import { ReactComponent as BillingIcon } from "images/icons/billing.svg";
import { ReactComponent as DevicesIcon } from "images/icons/devices.svg";
import { ReactComponent as IntegrationsIcon } from "images/icons/integrations.svg";
import { ReactComponent as MarketplaceIcon } from "images/icons/marketplace.svg";
import { ReactComponent as SettingsIcon } from "images/icons/settings.svg";
import { ReactComponent as SitesIcon } from "images/icons/sites.svg";
import { ReactComponent as UsersIcon } from "images/icons/users.svg";

const Sidebar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const isActive = false;

  return (
    <nav className="flex-none self-start sticky top-0">
      <div className="flex flex-col min-h-screen justify-between w-250">
        <div>
          <header className="p-5">
            <img
              src={require("images/sidebar_logo.png")}
              alt="Aerovy logo"
              onClick={() => navigate("/")}
              className="cursor-pointer h-[30px]"
            />
          </header>
          <ul className="px-2">
            {/* <NavLink to="/alerts">
              {({ isActive }) => ( */}
            <NavItem
              enabled={false}
              title="Alerts"
              icon={<AlertsIcon className="align-text-bottom mr-2 inline" />}
              criticalAlerts={1}
              warningAlerts={2}
              selected={isActive}
            />
            {/* )}
            </NavLink> */}
            <NavLink to="/dashboard">
              {({ isActive }) => (
                <NavItem
                  title="Overview"
                  icon={
                    <IntegrationsIcon className="align-text-bottom mr-2 inline" />
                  }
                  selected={isActive}
                />
              )}
            </NavLink>
            <NavLink to="/sites">
              {({ isActive }) => (
                <NavItem
                  title="Sites"
                  icon={<SitesIcon className="align-text-bottom mr-2 inline" />}
                  selected={isActive}
                />
              )}
            </NavLink>
            <NavLink to="/fleets">
              {({ isActive }) => (
                <NavItem
                  title="Fleets"
                  icon={<SitesIcon className="align-text-bottom mr-2 inline" />}
                  selected={isActive}
                />
              )}
            </NavLink>
            <NavLink to="/devices">
              {({ isActive }) => (
                <NavItem
                  title="Devices"
                  icon={
                    <DevicesIcon className="align-text-bottom mr-2 inline" />
                  }
                  selected={isActive}
                />
              )}
            </NavLink>
            {/* <NavLink to="/deviceList">
              {({ isActive }) => (
                <NavItem
                  title="Devices"
                  icon={<DevicesIcon className="align-text-bottom mr-2 inline" />}
                  selected={isActive}
                />
            )}
            </NavLink> */}
            <NavLink to="/transaction">
              {({ isActive }) => (
                <NavItem
                  title="Finance"
                  icon={
                    <BillingIcon className="align-text-bottom mr-2 inline" />
                  }
                  selected={isActive}
                />
              )}
            </NavLink>
            {/* <NavLink to="/thirdparty">
              {({ isActive }) => ( */}
            {/* <NavItem
              enabled={false}
              title="Integrations"
              icon={
                <IntegrationsIcon className="align-text-bottom mr-2 inline" />
              }
              selected={isActive}
            /> */}
            {/* )}
            </NavLink> */}
            {/* <NavLink to="/marketplace">
              {({ isActive }) => ( */}
            {/* <NavItem
              enabled={false}
              title="Marketplace"
              icon={
                <MarketplaceIcon className="align-text-bottom mr-2 inline" />
              }
              selected={isActive}
            /> */}
            {/* )}
            </NavLink> */}
            {/* <NavLink to="/billing">
              {({ isActive }) => ( */}
            {/* <NavItem
              enabled={false}
              title="Billing"
              icon={<BillingIcon className="align-text-bottom mr-2 inline" />}
              selected={isActive}
            /> */}
            {/* )}
            </NavLink> */}
            <NavLink to="/organization">
              {({ isActive }) => (
                <NavItem
                  title="Organization"
                  icon={<UsersIcon className="align-text-bottom mr-2 inline" />}
                  selected={isActive}
                />
              )}
            </NavLink>
            <NavLink to="/settings">
              {({ isActive }) => (
                <NavItem
                  title="Settings"
                  icon={
                    <SettingsIcon className="align-text-bottom mr-2 inline" />
                  }
                  selected={isActive}
                />
              )}
            </NavLink>
          </ul>
        </div>
        <footer className="p-8 flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <ul>
              <li className="text-caption my-2 text-blue50">
                API Documentation
              </li>
              <li className="text-caption my-2 text-blue50">
                Privacy &amp; Terms
              </li>
              <li className="text-caption my-2 text-blue50">Help</li>
            </ul>
            <p className="text-caption">&copy; 2023 Aerovy Mobility</p>
          </div>
          <hr className="border-gray95" />
          <div className="flex flex-row gap-2" title={user.name}>
            <img
              src={user.picture}
              alt="Profile"
              className="w-8 h-8 rounded-full bg-gray95"
            />
            <div className="truncate">
              <p className="text-caption text-space50 truncate">
                {user.nickname}
              </p>
              <p className="text-caption mt-0 text-space70 truncate">
                {user.name} testing
              </p>
            </div>
            <button className="text-caption text-red50" onClick={logout}>
              <FaSignOutAlt />
            </button>
          </div>
        </footer>
      </div>
    </nav>
  );
};

const NavItem = ({
  title,
  icon,
  selected,
  criticalAlerts,
  warningAlerts,
  enabled = true,
}) => {
  return (
    <li
      className={`text-heading3 flex justify-between w-full py-2.5 px-4 mb-1 rounded-md ${
        enabled
          ? "cursor-pointer hover:bg-blue95 hover:text-blue60"
          : "opacity-50 cursor-not-allowed"
      } ${selected ? "bg-blue95 text-blue50" : "text-space50"}`}
    >
      <div className="flex">
        <p className="hover:no-underline">
          {icon} {title}
        </p>
      </div>

      <p className="float-right flex">
        {criticalAlerts > 0 && (
          <span className="text-red-500 flex mr-2">
            <AlertIcon className="mr-1 mt-1.5" />
            {criticalAlerts}
          </span>
        )}
        {warningAlerts > 0 && (
          <span className="text-yellow-500 flex">
            <AlertIcon className="mr-1 mt-1.5" />
            {warningAlerts}
          </span>
        )}
      </p>
    </li>
  );
};

export default Sidebar;
