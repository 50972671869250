import { useState, useEffect } from 'react';
import { FaTrash } from 'react-icons/fa';

export const EditSiteModal = ({ isOpen, onClose, site, onSave, onDelete }) => {
  const [formData, setFormData] = useState({
    siteName: site.siteName,
    longitude: site.longitude,
    latitude: site.latitude,
    address: site.address,
  });

  const [errors, setErrors] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setFormData({
        siteName: site.siteName,
        longitude: site.longitude,
        latitude: site.latitude,
        address: site.address,
      });
      setErrors({});
      setIsDeleting(false);
    }
  }, [isOpen, site]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const validateForm = () => {
    let newErrors = {};

    if (!formData.siteName.trim()) {
      newErrors.siteName = 'Site name is required';
    }

    if (isNaN(formData.longitude) || formData.longitude < -180 || formData.longitude > 180) {
      newErrors.longitude = 'Longitude must be a number between -180 and 180';
    }

    if (isNaN(formData.latitude) || formData.latitude < -90 || formData.latitude > 90) {
      newErrors.latitude = 'Latitude must be a number between -90 and 90';
    }

    if (!formData.address.trim()) {
      newErrors.address = 'Address is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const success = await onSave(formData);
      if (success) {
        onClose();
      }
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this site? This action cannot be undone.')) {
      setIsDeleting(true);
      try {
        await onDelete(site.siteId);
        onClose();
      } catch (error) {
        console.error('Error deleting site:', error);
        alert('Failed to delete site. Please try again.');
      } finally {
        setIsDeleting(false);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed z-20 inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="flex items-center justify-center min-h-screen p-4">
        <div 
          className="fixed inset-0 transition-opacity" 
          aria-hidden="true"
          onClick={onClose}
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>

        <div 
          className="bg-white p-8 rounded-lg w-full max-w-2xl relative z-50"
          role="dialog" 
          aria-modal="true" 
          aria-labelledby="modal-headline"
        >
          <h2 className="text-2xl font-bold mb-6" id="modal-headline">Edit Site</h2>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-6">
              <div className="col-span-2">
                <label htmlFor="siteName" className="block text-sm font-medium text-gray-700 mb-1">Site Name</label>
                <input
                  type="text"
                  id="siteName"
                  name="siteName"
                  value={formData.siteName}
                  onChange={handleChange}
                  className={`w-full border rounded-md px-3 py-2 ${errors.siteName ? 'border-red-500' : 'border-gray-300'}`}
                />
                {errors.siteName && <p className="text-red-500 text-xs mt-1">{errors.siteName}</p>}
              </div>
              <div>
                <label htmlFor="longitude" className="block text-sm font-medium text-gray-700 mb-1">Longitude</label>
                <input
                  type="number"
                  id="longitude"
                  name="longitude"
                  value={formData.longitude}
                  onChange={handleChange}
                  step="any"
                  className={`w-full border rounded-md px-3 py-2 ${errors.longitude ? 'border-red-500' : 'border-gray-300'}`}
                />
                {errors.longitude && <p className="text-red-500 text-xs mt-1">{errors.longitude}</p>}
              </div>
              <div>
                <label htmlFor="latitude" className="block text-sm font-medium text-gray-700 mb-1">Latitude</label>
                <input
                  type="number"
                  id="latitude"
                  name="latitude"
                  value={formData.latitude}
                  onChange={handleChange}
                  step="any"
                  className={`w-full border rounded-md px-3 py-2 ${errors.latitude ? 'border-red-500' : 'border-gray-300'}`}
                />
                {errors.latitude && <p className="text-red-500 text-xs mt-1">{errors.latitude}</p>}
              </div>
              <div className="col-span-2">
                <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">Address</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className={`w-full border rounded-md px-3 py-2 ${errors.address ? 'border-red-500' : 'border-gray-300'}`}
                />
                {errors.address && <p className="text-red-500 text-xs mt-1">{errors.address}</p>}
              </div>
            </div>
            
            <div className="mt-8 space-y-4">
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 rounded-md border border-gray-300 text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 rounded-md border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Save Changes
                </button>
              </div>
              
              <div className="pt-4 border-t border-gray-200">
                <button
                  type="button"
                  onClick={handleDelete}
                  disabled={isDeleting}
                  className="w-full px-4 py-2 rounded-md border border-red-300 text-red-600 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 flex items-center justify-center"
                >
                  <FaTrash className="mr-2" />
                  {isDeleting ? 'Deleting...' : 'Delete Site'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};