import { useAuth } from "context/AuthContext";

const Settings = () => {
  const { logout } = useAuth();

  return (
    <>
      <div className="text-heading1 text-space50 pt-4 pb-5">Settings</div>
      <button
        type="submit"
        className="border-red40 bg-red50 hover:bg-red60 elevation-2 mb-3 items-center justify-center gap-2 rounded border px-4 py-2.5 font-bold text-white"
        onClick={logout}
      >
        Logout
      </button>
    </>
  );
};

export default Settings;
