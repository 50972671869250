// TODO: this isnt exhaustive
export const typeToLabel = (type: string) => {
  switch (type) {
    case "net":
      return "Net";
    case "fwd":
      return "Forward";
    case "rev":
      return "Reverse";
    case "max":
      return "Maximum";
    case "gen":
      return "Generated";
    case "total":
      return "Total";
    case "grid":
      return "Grid Draw";
    case "soc":
      return "Capacity";
    case "soh":
      return "Health";
    default:
      return type;
  }
};
