import { SiteLevel, SiteResponse, usePlacesApi } from "api/ingestion/places.ts";
import { useSelectedSiteLevel } from "context/SelectedSiteLevelContext.tsx";

import { useEffect, useState } from "react";

const depthGaps = ["gap-3", "gap-2", "gap-1", "gap-0"];
const depthMargins = ["mb-3", "mb-2", "mb-1", "mb-0"];

const Level = ({
  level,
  children,
  depth = 0,
}: {
  level: SiteLevel;
  children: React.ReactNode;
  depth: number;
}) => {
  const { selectedSiteLevel, setSelectedSiteLevel } = useSelectedSiteLevel();
  const isSelected = selectedSiteLevel?.levelId === level.levelId;
  const selectedClasses = isSelected
    ? "bg-blue97 border-2 border-blue50"
    : "border-gray-200 hover:-translate-y-1 transition-all hover:shadow-sm hover:bg-gray-50";

  const toggleSelection = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (isSelected) {
      setSelectedSiteLevel(null);
    } else {
      setSelectedSiteLevel(level);
    }
  };

  return (
    <div
      onClick={toggleSelection}
      className={`p-3 cursor-pointer break-inside-avoid-column ${depthMargins[depth]} border rounded-md ${selectedClasses}`}
    >
      <p className="text-caption">
        <span className="text-space80">{level.levelType} / </span>
        {level.levelName}
        <span className="text-sm text-blue-500 float-right">&rarr;</span>
      </p>
      {depth < 2 && (
        <p className="text-footnote text-space70">{level.address}</p>
      )}
      <div className={`hidden-if-empty mt-3 ${depthGaps[depth]} columns-3`}>
        {children}
      </div>
    </div>
  );
};

const Mapper = ({
  level,
  levelMap,
  naturalLevelOrder,
  depth = 0,
}: {
  level: SiteLevel;
  levelMap: Map<string, SiteLevel>;
  naturalLevelOrder: string[];
  depth: number;
}) => {
  const children = new Set(levelMap.get(level.levelId)?.successorIds);
  const naturalChildren = naturalLevelOrder.filter((id) => children.has(id));

  const inside = (
    <>
      {naturalChildren.map((id) => (
        <Mapper
          level={levelMap.get(id)}
          levelMap={levelMap}
          naturalLevelOrder={naturalLevelOrder}
          key={id}
          depth={depth + 1}
        />
      ))}
    </>
  );

  if (depth === 0) {
    return inside;
  }

  return (
    <Level level={level} depth={depth}>
      {inside}
    </Level>
  );
};

const TopologyOverview = ({ siteId }: { siteId: string }) => {
  const [levels, setLevels] = useState([]);
  const [naturalLevelOrder, setNaturalLevelOrder] = useState([]);
  const [levelsAsMap, setLevelsAsMap] = useState(new Map<string, SiteLevel>());

  const { getSiteLevels } = usePlacesApi();

  useEffect(() => {
    getSiteLevels(siteId).then((data) => {
      setLevels(data);
      setLevelsAsMap(
        new Map<string, SiteLevel>(data.map((level) => [level.levelId, level])),
      );
      setNaturalLevelOrder(data.map((level) => level.levelId));
    });
  }, [siteId]);

  if (levels.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-3">
      <p className="text-space50 text-heading3 mb-4">Site Topology</p>
      <Mapper
        level={levels[0]}
        levelMap={levelsAsMap}
        naturalLevelOrder={naturalLevelOrder}
      />
    </div>
  );
};

export { TopologyOverview };