type Auth0Config = {
  domain: string;
  clientId: string;
};

const env = process.env.REACT_APP_ENV;

const getConfig = (env: string): Auth0Config => {
  switch (env) {
    case "local":
    case "development":
      return {
        domain: "auth.spectra.dev.aerovy.com",
        clientId: "A0MSTVYk3mWrHVyS4RuL25msSp9QKCOS",
      };

    case "staging":
      return {
        domain: "auth.spectra.staging.aerovy.com",
        clientId: "TxAAkQFGMzqmosCjephYyExHlVw0BZxr",
      };

    default:
      throw new Error(`Unsupported environment: ${env}`);
  }
};

export const auth0Config: Auth0Config = getConfig(env);
