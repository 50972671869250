import { useAuthFetch } from "context/AuthContext";

const getBaseURL = () => {
  switch (process.env.REACT_APP_ENV) {
    case "production":
      return "https://spectra.api.aerovy.com";
    case "staging":
      return "https://spectra.api.staging.aerovy.com";
    case "development":
      return "https://spectra.api.dev.aerovy.com";
    default:
      return "/enterprise";
  }
};

export const BASE_URL = getBaseURL();

export const useEnterpriseApi = () => {
  const { authFetch } = useAuthFetch();

  const getSwapStationsData = async (partnerId: string): Promise<any> => {
    return await authFetch(
      `${BASE_URL}/swap-stations/data?partnerId=${encodeURIComponent(partnerId)}`,
      {
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getSwapStationData = async (
    partnerId: string,
    swapStationId: string,
    placeId: string
  ): Promise<any> => {
    const params = new URLSearchParams({
      partnerId,
      placeId,
    });
    
    return await authFetch(
      `${BASE_URL}/swap-stations/data/${swapStationId}?${params}`,
      {
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getSwapStationBatteries = async (
    partnerId: string,
    swapStationId: string,
    placeId: string
  ): Promise<any> => {
    const params = new URLSearchParams({
      partnerId,
      placeId,
    });
    
    return await authFetch(
      `${BASE_URL}/swap-stations/data/${swapStationId}/batteries?${params}`,
      {
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getBatteryState = async (
    partnerId: string,
    swapStationId: string,
    placeId: string,
    batteryManufacturerId: string,
  ): Promise<any> => {
    const params = new URLSearchParams({
      partnerId,
      placeId,
    });
    
    return await authFetch(
      `${BASE_URL}/swap-stations/data/${swapStationId}/battery/${batteryManufacturerId}?${params}`,
      {
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const rentBattery = async (
    swapStationPartnerId: string,
    swapStationThingId: string,
    swapStationManufacturerId: string,
    swapStationPlaceId: string,
    swapStationDoorId: string,
    batteryManufacturerId: string,
  ): Promise<any> => {
    return await authFetch(
      `${BASE_URL}/swap-stations/control/rent`,
      {
        method: 'POST',
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          swapStationPartnerId,
          swapStationThingId,
          swapStationManufacturerId,
          swapStationPlaceId,
          swapStationDoorId,
          batteryManufacturerId,
        }),
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const returnBattery = async (
    swapStationPartnerId: string,
    swapStationThingId: string,
    swapStationManufacturerId: string,
    swapStationPlaceId: string,
    swapStationDoorId: string,
    batteryManufacturerId: string,
  ): Promise<any> => {
    return await authFetch(
      `${BASE_URL}/swap-stations/control/return`,
      {
        method: 'POST',
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          swapStationPartnerId,
          swapStationThingId,
          swapStationManufacturerId,
          swapStationPlaceId,
          swapStationDoorId,
          batteryManufacturerId,
        }),
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const checkTransactionStatus = async (
    swapStationPartnerId: string,
    swapStationThingId: string,
    swapStationPlaceId: string,
    transactionId: string,
  ): Promise<any> => {
    const response = await authFetch(
      `${BASE_URL}/swap-stations/control/status`,
      {
        method: 'POST',
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          swapStationPartnerId,
          swapStationThingId,
          swapStationPlaceId,
          transactionId,
        }),
      },
    );
    
    if (!response.ok) {
      throw new Error(`HTTP error, status: ${response.status}`);
    }
    
    return response.json();
  };

  const confirmControl = async (
    controlName: 'rent' | 'return' | 'swap',
    transactionId: string,
    recordId: string,
    swapStationPartnerId: string,
    swapStationManufacturerId: string,
    swapStationThingId: string,
    swapStationPlaceId: string,
  ): Promise<any> => {
    return await authFetch(
      `${BASE_URL}/swap-stations/control/${controlName}/confirm`,
      {
        method: 'POST',
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          transactionId,
          recordId,
          swapStationPartnerId,
          swapStationManufacturerId,
          swapStationThingId,
          swapStationPlaceId,
        }),
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const swapBattery = async (
    swapStationPartnerId: string,
    swapStationManufacturerId: string,
    swapStationThingId: string,
    swapStationPlaceId: string,
    swapStationReturnDoorId: string,
    emptyBatteryManufacturerId: string,
    fullBatteryManufacturerId: string,
    fullBatteryDoorId: string,
  ): Promise<any> => {
    return await authFetch(
      `${BASE_URL}/swap-stations/control/swap`,
      {
        method: 'POST',
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          swapStationPartnerId,
          swapStationManufacturerId,
          swapStationThingId,
          swapStationPlaceId,
          swapStationReturnDoorId,
          emptyBatteryManufacturerId,
          fullBatteryManufacturerId,
          fullBatteryDoorId,
        }),
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const rebootSwapStation = async (
    swapStationPartnerId: string,
    swapStationThingId: string,
    swapStationManufacturerId: string,
    swapStationPlaceId: string,
  ): Promise<any> => {
    return await authFetch(
      `${BASE_URL}/swap-stations/control/reboot`,
      {
        method: 'POST',
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          swapStationPartnerId,
          swapStationThingId,
          swapStationManufacturerId,
          swapStationPlaceId,
        }),
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const refreshSwapStation = async (
    swapStationPartnerId: string,
    swapStationThingId: string,
    swapStationManufacturerId: string,
    swapStationPlaceId: string,
  ): Promise<any> => {
    return await authFetch(
      `${BASE_URL}/swap-stations/data/refresh`,
      {
        method: 'POST',
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          swapStationPartnerId,
          swapStationThingId,
          swapStationManufacturerId,
          swapStationPlaceId,
        }),
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const openDoors = async (
    swapStationPartnerId: string,
    swapStationThingId: string,
    swapStationManufacturerId: string,
    swapStationPlaceId: string,
    swapStationDoorId: string[],
  ): Promise<any> => {
    return await authFetch(
      `${BASE_URL}/swap-stations/control/doors/open`,
      {
        method: 'POST',
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          swapStationPartnerId,
          swapStationThingId,
          swapStationManufacturerId,
          swapStationPlaceId,
          swapStationDoorId,
        }),
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res;
    });
  };

  const openAllDoors = async (
    swapStationPartnerId: string,
    swapStationThingId: string,
    swapStationManufacturerId: string,
    swapStationPlaceId: string,
    maxDoorId: number,
  ): Promise<any> => {
    return await authFetch(
      `${BASE_URL}/swap-stations/control/doors/open/all`,
      {
        method: 'POST',
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          swapStationPartnerId,
          swapStationThingId,
          swapStationManufacturerId,
          swapStationPlaceId,
          maxDoorId,
        }),
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res;
    });
  };

  return {
    getSwapStationsData,
    getSwapStationData,
    getSwapStationBatteries,
    getBatteryState,
    rentBattery,
    returnBattery,
    swapBattery,
    checkTransactionStatus,
    confirmControl,
    rebootSwapStation,
    refreshSwapStation,
    openDoors,
    openAllDoors,
  };
};