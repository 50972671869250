import { UserDetails, useIdentityApi } from "api/ingestion/identity";
import { useAuth } from "context/AuthContext";

import React, { useEffect, useState } from "react";
import { FaPencilAlt, FaTrash } from "react-icons/fa";

import { DeleteUserModal } from "./deleteModal";
import { EditUserModal } from "./editModal";

const UserRow = ({
  user,
  handleOpenEditModal,
  handleOpenDeleteModal,
  editable,
}: {
  user: UserDetails;
  handleOpenEditModal: (userId: string) => void;
  handleOpenDeleteModal: (userId: string) => void;
  editable: boolean;
}) => {
  return (
    <tr key={user.userId}>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <div className="h-11 w-11 flex-shrink-0">
            <img alt="" src={user.picture} className="h-11 w-11 rounded-full" />
          </div>
          <div className="ml-4">
            <div className="font-medium text-space10">{user.name}</div>
            <div className="mt-1 text-space70">{user.email}</div>
          </div>
        </div>
      </td>
      {editable && (
        <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
          <a
            href="#"
            onClick={() => handleOpenEditModal(user.userId)}
            className="text-blue50 hover:text-blue20"
          >
            <FaPencilAlt />
            <span className="sr-only">Edit, {user.name}</span>
          </a>
        </td>
      )}
      {editable && (
        <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
          <a
            href="#"
            onClick={() => handleOpenDeleteModal(user.userId)}
            className="text-red50 hover:text-red20"
          >
            <FaTrash />
            <span className="sr-only">Delete, {user.name}</span>
          </a>
        </td>
      )}
    </tr>
  );
};

const UsersTable = ({
  users,
  refresh,
  editable,
}: {
  users: UserDetails[] | null;
  refresh: () => Promise<void>;
  editable: boolean;
}) => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [userIdToDelete, setUserIdToDelete] = useState<string | null>(null);

  if (!users)
    return <div className="text-center text-space50 pt-4">Loading...</div>;

  const handleCloseEditModal = () => {
    setSelectedUserId(null);
    refresh();
  };
  const handleCloseDeleteModal = () => {
    setUserIdToDelete(null);
    refresh();
  };

  return (
    <div className="overflow-x-auto">
      <EditUserModal
        userId={selectedUserId}
        open={selectedUserId !== null}
        setOpen={handleCloseEditModal}
      />
      <DeleteUserModal
        userId={userIdToDelete}
        open={userIdToDelete !== null}
        setOpen={handleCloseDeleteModal}
      />
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-space10 sm:pl-0"
            >
              &nbsp;<span className="sr-only">User</span>
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
              <span className="sr-only">Edit</span>
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
              <span className="sr-only">Delete</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {users.map((user) => (
            <UserRow
              key={user.userId}
              user={user}
              handleOpenEditModal={setSelectedUserId}
              handleOpenDeleteModal={setUserIdToDelete}
              editable={editable}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const UsersSection = ({ editable }: { editable: boolean }) => {
  const { getUsersForOrganization } = useIdentityApi();
  const { user } = useAuth();

  const [users, setUsers] = useState<UserDetails[] | null>(null);

  const fetchAllUsers = async () => {
    let allUsers: UserDetails[] = [];
    let page = 0;
    let pageSize = 50;
    let fetchedUsers: UserDetails[] = [];

    do {
      fetchedUsers = await getUsersForOrganization(user.org_id, page, pageSize);
      allUsers = [...allUsers, ...fetchedUsers];
      page++;
    } while (fetchedUsers.length === pageSize);
    setUsers(allUsers);
  };

  useEffect(() => {
    fetchAllUsers();
  }, [user.org_id]);

  return (
    <div>
      <div className="flex justify-between">
        <span className="text-heading1 text-space50">Organization Members</span>
      </div>
      <UsersTable users={users} refresh={fetchAllUsers} editable={editable} />
    </div>
  );
};
