import { useThingsApi } from "api/ingestion/things";
import React, { useEffect, useState } from "react";

const EditDeviceModal = ({
  isOpen,
  onClose,
  onDeviceEdited,
  device,
  placeType,
  placeId,
}) => {
  const [formData, setFormData] = useState({
    thingName: "",
    thingDescription: "",
    address: "",
    thingManufacturerId: "",
    longitude: "",
    latitude: "",
    altitude: "",
    integrationId: "",
    levelId: "",
    primaryGroup: "",
  });

  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const { editThing, editThingPrimaryGroup } = useThingsApi();

  useEffect(() => {
    if (device) {
      setFormData({
        thingName: device.thingName || "",
        thingDescription: device.thingDescription || "",
        address: device.address || "",
        thingManufacturerId: device.thingManufacturerId || "",
        longitude: device.longitude || "",
        latitude: device.latitude || "",
        altitude: device.altitude || "",
        integrationId: device.integrationId || "",
        levelId: device.levelId || "",
        primaryGroup: device.primaryGroup || "",
        placeType: placeType,
      });
    }
  }, [device, placeType]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = {};

    if (!formData.thingName) {
      newErrors.thingName = "Thing Name is required";
      valid = false;
    }

    if (!formData.thingDescription) {
      newErrors.thingDescription = "Description is required";
      valid = false;
    }

    if (formData.latitude === "") {
      newErrors.latitude = "Latitude is required";
      valid = false;
    } else {
      const lat = parseFloat(formData.latitude);
      if (isNaN(lat) || lat < -90 || lat > 90) {
        newErrors.latitude = "Latitude must be between -90 and 90";
        valid = false;
      }
    }

    if (formData.longitude === "") {
      newErrors.longitude = "Longitude is required";
      valid = false;
    } else {
      const lng = parseFloat(formData.longitude);
      if (isNaN(lng) || lng < -180 || lng > 180) {
        newErrors.longitude = "Longitude must be between -180 and 180";
        valid = false;
      }
    }

    if (formData.altitude === "") {
      newErrors.altitude = "Altitude is required";
      valid = false;
    } else {
      const alt = parseFloat(formData.altitude);
      if (isNaN(alt)) {
        newErrors.altitude = "Altitude must be a number";
        valid = false;
      }
    }

    if (placeType === "Site" && !formData.levelId) {
      newErrors.levelId = "Level ID is required for Site devices";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      // First update the device details
      const thingResult = await editThing(
        placeType,
        placeId,
        device.thingId,
        formData
      );

      if (!thingResult.success) {
        setErrorMessage(thingResult.error);
        return;
      }

      // Then update the primary group if it's changed
      if (formData.primaryGroup && formData.primaryGroup !== device.primaryGroup) {
        const groupResult = await editThingPrimaryGroup(
          placeType,
          placeId,
          device.thingId,
          formData.primaryGroup
        );

        if (!groupResult.success) {
          setErrorMessage(groupResult.error);
          return;
        }
      }

      onDeviceEdited();
      handleClose();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleClose = () => {
    onClose();
    setErrors({});
    setErrorMessage("");
  };

  return isOpen ? (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl mb-4">Edit Device</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="thingName"
            >
              Name*
            </label>
            <input
              type="text"
              name="thingName"
              id="thingName"
              value={formData.thingName}
              onChange={handleChange}
              className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.thingName ? "border-red-500" : ""
              }`}
            />
            {errors.thingName && (
              <p className="text-red-500 text-xs italic">{errors.thingName}</p>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="thingDescription"
            >
              Description*
            </label>
            <input
              type="text"
              name="thingDescription"
              id="thingDescription"
              value={formData.thingDescription}
              onChange={handleChange}
              className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.thingDescription ? "border-red-500" : ""
              }`}
            />
            {errors.thingDescription && (
              <p className="text-red-500 text-xs italic">{errors.thingDescription}</p>
            )}
          </div>

          <div className="grid grid-cols-3 gap-4">
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="latitude"
              >
                Latitude*
              </label>
              <input
                type="number"
                name="latitude"
                id="latitude"
                value={formData.latitude}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.latitude ? "border-red-500" : ""
                }`}
              />
              {errors.latitude && (
                <p className="text-red-500 text-xs italic">{errors.latitude}</p>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="longitude"
              >
                Longitude*
              </label>
              <input
                type="number"
                name="longitude"
                id="longitude"
                value={formData.longitude}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.longitude ? "border-red-500" : ""
                }`}
              />
              {errors.longitude && (
                <p className="text-red-500 text-xs italic">{errors.longitude}</p>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="altitude"
              >
                Altitude*
              </label>
              <input
                type="number"
                name="altitude"
                id="altitude"
                value={formData.altitude}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.altitude ? "border-red-500" : ""
                }`}
              />
              {errors.altitude && (
                <p className="text-red-500 text-xs italic">{errors.altitude}</p>
              )}
            </div>
          </div>

          {placeType === "Site" && (
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="levelId"
              >
                Level ID*
              </label>
              <input
                type="text"
                name="levelId"
                id="levelId"
                value={formData.levelId}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.levelId ? "border-red-500" : ""
                }`}
              />
              {errors.levelId && (
                <p className="text-red-500 text-xs italic">{errors.levelId}</p>
              )}
            </div>
          )}

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="primaryGroup"
            >
              Primary Group
            </label>
            <input
              type="text"
              name="primaryGroup"
              id="primaryGroup"
              value={formData.primaryGroup}
              onChange={handleChange}
              className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          {errorMessage && (
            <p className="text-red-500 text-xs italic mb-4">{errorMessage}</p>
          )}

          <div className="flex justify-end space-x-4">
            <button
              type="submit"
              className="px-3.5 py-2 rounded-full border border-space80 bg-blue-600 justify-end items-center gap-1 cursor-pointer flex"
            >
              <div className="text-white text-xs font-medium leading-[14px]">
                Save Changes
              </div>
            </button>
            <button
              type="button"
              onClick={handleClose}
              className="px-3.5 py-2 rounded-full border border-space80 justify-end items-center gap-1 cursor-pointer flex"
            >
              <div className="text-space70 text-xs font-medium leading-[14px]">
                Cancel
              </div>
            </button>
          </div>
        </form>
      </div>
    </div>
  ) : null;
};

export default EditDeviceModal;