import { useState } from "react";
import { ReactComponent as DownloadIcon } from "images/icons/download.svg";

interface CreateCertificateModalProps {
  isOpen: boolean;
  onClose: () => void;
  thingId: string;
  onGenerate: () => Promise<void>;
  isGenerating: boolean;
  certificateDetails: CertificateDetails | null;
}

export const CreateCertificateModal: React.FC<CreateCertificateModalProps> = ({
  isOpen,
  onClose,
  thingId,
  onGenerate,
  isGenerating,
  certificateDetails,
}) => {
  const [downloadStatus, setDownloadStatus] = useState({
    certificate: false,
    publicKey: false,
    privateKey: false,
  });

  const allDownloadsComplete = Object.values(downloadStatus).every(
    (status) => status,
  );

  const handleDownload = (type: "certificate" | "publicKey" | "privateKey") => {
    if (!certificateDetails) return;

    let content = "";
    let fileName = "";

    switch (type) {
      case "certificate":
        content = certificateDetails.certificatePem;
        fileName = `certificate-${thingId}.pem.crt`;
        break;
      case "publicKey":
        content = certificateDetails.publicKey;
        fileName = `public-key-${thingId}.pem.key`;
        break;
      case "privateKey":
        content = certificateDetails.privateKey;
        fileName = `private-key-${thingId}.pem.key`;
        break;
    }

    const link = document.createElement("a");
    link.download = fileName;
    const blob = new Blob([content], { type: "text/plain" });
    link.href = window.URL.createObjectURL(blob);
    link.click();

    setDownloadStatus((prev) => ({
      ...prev,
      [type]: true,
    }));
  };

  if (!isOpen) return null;

  if (!certificateDetails) {
    return (
      <div
        className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
        onClick={onClose}
      >
        <div
          className="bg-white rounded-lg p-6 max-w-lg w-full mx-4"
          onClick={(e) => e.stopPropagation()}
        >
          <h3 className="text-2xl font-medium mb-4">Create Certificate</h3>
          <div className="space-y-4">
            <div>
              <p className="text-gray-600">Certificate Policy "policy-name"</p>
              <p className="text-gray-600">Device "{thingId}"</p>
            </div>
          </div>
          <div className="mt-6 flex justify-end gap-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:text-gray-800"
            >
              Cancel
            </button>
            <button
              onClick={onGenerate}
              disabled={isGenerating}
              className="flex items-center gap-2 px-4 py-2 bg-blue-700 text-white rounded hover:bg-blue-800 disabled:opacity-50"
            >
              {isGenerating ? "Generating..." : "Create"}{" "}
              {!isGenerating && <span>+</span>}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg p-6 max-w-lg w-full mx-4"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-xl font-medium mb-4">Download Certificates</h2>

        <p className="text-red-500 mb-8">
          Please download and keep these certificates in a safe place. These
          certificates will not be available again.
        </p>

        <div className="flex gap-4">
          <button
            onClick={() => handleDownload("certificate")}
            className="w-full px-4 py-3 bg-blue-600 text-white rounded flex items-center gap-2 hover:bg-blue-700"
          >
            <DownloadIcon/>
            Certificate
          </button>

          <button
            onClick={() => handleDownload("publicKey")}
            className="w-full px-4 py-3 bg-blue-600 text-white rounded flex items-center gap-2 hover:bg-blue-700"
          >
            <DownloadIcon/>
            Public Key
          </button>

          <button
            onClick={() => handleDownload("privateKey")}
            className="w-full px-4 py-3 bg-blue-700 text-white rounded flex items-center gap-2 hover:bg-blue-600"
          >
            <DownloadIcon/>
            Private Key
          </button>
        </div>

        <p className="text-gray-600 mt-6 mb-4">
          You can download the root CA certificate from the "Security" tab at
          any time.
        </p>

        <div className="flex justify-end items-center gap-3 mt-6">
          {!allDownloadsComplete && (
            <p className="text-red-500">Please download all three files</p>
          )}
          <button
            disabled={!allDownloadsComplete}
            className="px-8 py-2 rounded bg-blue-700 text-white disabled:bg-gray-300"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};
