import { useState } from "react";

import { Policy } from "../types/policy";
import { PolicyDocument } from "../types/policy";
import { NewPolicyEntryModal } from "./NewPolicyEntryModal";
import { ReactComponent as CopyIcon } from "images/icons/copy.svg";
import { ReactComponent as DeleteIcon } from "images/icons/delete.svg";

type EntryType = "connection" | "subscribe" | "publish";

interface PolicyDetailsModalProps {
  policy: Policy | null;
  onClose: () => void;
  onDelete: (type: "connect" | "subscribe" | "publish", value: string) => void;
  thingId: string;
  currentPolicy: PolicyDocument | string;
  onUpdate: (policy: PolicyDocument) => Promise<void>;
}

export const PolicyDetailsModal: React.FC<PolicyDetailsModalProps> = ({
  policy,
  onClose,
  onDelete,
  thingId,
  currentPolicy,
  onUpdate,
}) => {
  const [isNewEntryModalOpen, setIsNewEntryModalOpen] = useState(false);

  if (!policy) return null;

  const parsedCurrentPolicy =
    typeof currentPolicy === "string"
      ? JSON.parse(currentPolicy)
      : currentPolicy;

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).catch((err) => {
      console.error("Failed to copy text:", err);
    });
  };

  return (
    <div
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-[9999]"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg p-6 w-full max-w-4xl mx-4"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-8">
          <div>
            <div className="text-sm text-gray-600 uppercase">
              BATTERY #{thingId}
            </div>
            <h2 className="text-xl font-medium">policy_name</h2>
          </div>
          <button
            onClick={() => setIsNewEntryModalOpen(true)}
            className="px-4 py-2 text-blue-700 border border-blue-700 rounded hover:bg-blue-50 flex items-center gap-2"
          >
            <span>+</span>
            <span>New Entry</span>
          </button>
        </div>

        <NewPolicyEntryModal
          isOpen={isNewEntryModalOpen}
          onClose={() => setIsNewEntryModalOpen(false)}
          currentPolicy={parsedCurrentPolicy}
          onUpdate={onUpdate}
        />

        <div
          className="overflow-y-auto pb-20"
          style={{ maxHeight: "calc(100vh - 200px)" }}
        >
          <div className="space-y-8">
            <div>
              <h3 className="font-medium mb-4">Allowed Connection Endpoints</h3>
              <div className="space-y-2">
                {policy.connectionEndpoints.length > 0 ? (
                  policy.connectionEndpoints.map((endpoint, index) => (
                    <div
                      key={index}
                      className="bg-gray-50 rounded-lg px-4 py-3 flex items-center justify-between"
                    >
                      <span className="text-sm font-mono">{`client/${endpoint}`}</span>
                      <div className="flex gap-2">
                        <button
                          onClick={() => copyToClipboard(`client/${endpoint}`)}
                          className="text-gray-400 hover:text-gray-600"
                          title="Copy"
                        >
                          <CopyIcon/>
                        </button>
                        <button
                          onClick={() => onDelete("connect", endpoint)}
                          className="text-red-400 hover:text-red-600"
                          title="Delete"
                        >
                          <DeleteIcon/>
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-gray-500 text-sm">
                    No Allowed Connection Endpoints
                  </div>
                )}
              </div>
            </div>

            <div>
              <h3 className="font-medium mb-4">Allowed Subscribe Topics</h3>
              <div className="space-y-2">
                {policy.subscribeTopics.length > 0 ? (
                  policy.subscribeTopics.map((topic, index) => (
                    <div
                      key={index}
                      className="bg-gray-50 rounded-lg px-4 py-3 flex items-center justify-between"
                    >
                      <span className="text-sm font-mono">{topic}</span>
                      <div className="flex gap-2">
                        <button
                          onClick={() => copyToClipboard(topic)}
                          className="text-gray-400 hover:text-gray-600"
                          title="Copy"
                        >
                          <CopyIcon/>
                        </button>
                        <button
                          onClick={() => onDelete("subscribe", topic)}
                          className="text-red-400 hover:text-red-600"
                          title="Delete"
                        >
                          <DeleteIcon/>
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-gray-500 text-sm">
                    No Allowed Subscribe Topics
                  </div>
                )}
              </div>
            </div>

            <div>
              <h3 className="font-medium mb-4">Allowed Publish Topics</h3>
              <div className="space-y-2">
                {policy.publishTopics.length > 0 ? (
                  policy.publishTopics.map((topic, index) => (
                    <div
                      key={index}
                      className="bg-gray-50 rounded-lg px-4 py-3 flex items-center justify-between"
                    >
                      <span className="text-sm font-mono">{topic}</span>
                      <div className="flex gap-2">
                        <button
                          onClick={() => copyToClipboard(topic)}
                          className="text-gray-400 hover:text-gray-600"
                          title="Copy"
                        >
                          <CopyIcon/>
                        </button>
                        <button
                          onClick={() => {onDelete("publish", topic)}}
                          className="text-red-400 hover:text-red-600"
                          title="Delete"
                        >
                          <DeleteIcon/>
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-gray-500 text-sm">
                    No Allowed Publish Topics
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="px-6 py-2 bg-blue-700 text-white rounded hover:bg-blue-800"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
