import { useAuthFetch } from "../context/AuthContext";

let baseUrl;

switch (process.env.REACT_APP_ENV) {
  case "production":
    baseUrl = "https://ingestion.spectra.api.aerovy.com/v1";
    break;
  case "staging":
    baseUrl = "https://ingestion.spectra.api.staging.aerovy.com/v1";
    break;
  case "development":
    baseUrl = "https://ingestion.spectra.api.dev.aerovy.com/v1";
    break;
  default:
    baseUrl = "/ingestion/v1";
}

export const useIngestionApi = () => {
  const { authFetch } = useAuthFetch();

  const validateToken = async (integrationId, token) => {
    const getValidateTokenUrl = `${baseUrl}/integrations/${integrationId}/validate/${token}`;

    try {
      const response = await authFetch(getValidateTokenUrl, {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        return { isValid: true };
      } else {
        return { isValid: false, error: "Validation failed" };
      }
    } catch (error) {
      return { isValid: false, error: error.message };
    }
  };

  const validateTokenFlow = async (token, partnerId) => {
    const getIntegrationsUrl = `${baseUrl}/integrations`;

    try {
      const response = await authFetch(getIntegrationsUrl, {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      });
      const integrations = await response.json();
      const integration = integrations.find(
        (integration) => integration.partnerId === partnerId,
      );
      if (integration) {
        return validateToken(integration.integrationId, token);
      } else {
        console.error(
          "Integration not found for the specified partnerId and thirdPartyId.",
        );
        return { isValid: false, error: "Integration not found" };
      }
    } catch (error) {
      console.error("Error fetching integrations:", error);
      return { isValid: false, error: error.message };
    }
  };

  const thirdPartyFlow = async (parameters, providerName, partnerId) => {
    const getIntegrationsUrl = `${baseUrl}/integrations`;
    console.log(getIntegrationsUrl);

    try {
      const response = await authFetch(getIntegrationsUrl, {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      });
      const integrations = await response.json();
      const integration = integrations.find(
        (integration) =>
          integration.partnerId === partnerId &&
          integration.thirdPartyId.toLowerCase() === providerName.toLowerCase(),
        // integration.metaData?.
      );
      console.log(integration);
      if (integration) {
        updateIntegrationMetaData(parameters, integration);
        return integration.integrationId;
      } else {
        console.error(
          "Integration not found for the specified partnerId and thirdPartyId.",
        );
        return null;
      }
    } catch (error) {
      console.error("Error fetching integrations:", error);
      throw error;
    }
  };

  const updateIntegrationMetaData = async (parameters, integration) => {
    const getIntegrationsUrl = `${baseUrl}/integrations/${integration?.integrationId}/metaData`;
    const metaDataPayload = {
      metaData: parameters,
      partnerThirdPartyId: integration?.partnerThirdPartyId,
    };

    console.log(getIntegrationsUrl);
    console.log(metaDataPayload);

    try {
      const response = await authFetch(getIntegrationsUrl, {
        method: "PATCH",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(metaDataPayload),
      });
      if (!response.ok) {
        throw new Error("Failed to update integration metaData.");
      }
    } catch (error) {
      console.error("Error updating integration metaData:", error);
    }
  };

  const postIntegration = async (selectedSiteId, customerId) => {
    const getIntegrationsUrl = `${baseUrl}/integrations`;
    console.log(getIntegrationsUrl);

    const payload = {
      siteId: selectedSiteId,
      thirdPartyId: "SCE",
      partnerThirdPartyId: customerId,
      metaData: {},
    };

    try {
      const response = await authFetch(getIntegrationsUrl, {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          errorText || "Initialization failed. Please try again.",
        );
      }
      return { success: true };
    } catch (error) {
      return { success: false, error: error.message };
    }
  };

  const getSites = async () => {
    const getSitesUrl = `${baseUrl}/sites/`;
    try {
      const response = await authFetch(getSitesUrl, {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error, status: ${response.status}`);
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Fetch Error:", error);
      throw error;
    }
  };

  return {
    validateToken,
    validateTokenFlow,
    thirdPartyFlow,
    postIntegration,
    getSites,
  };
};
