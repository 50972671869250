import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { SiteResponse, usePlacesApi } from "api/ingestion/places.ts";
import { Thing, useThingsApi } from "api/ingestion/things.ts";

const DeviceList = () => {
  const navigate = useNavigate();
  const { getSites } = usePlacesApi();
  const { getThings, getThingTypes } = useThingsApi();

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [sites, setSites] = useState<SiteResponse[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<string[]>([]);
  const [devices, setDevices] = useState<Thing[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const fetchedSites = await getSites();
        const fetchedDeviceTypes = await getThingTypes();
        const fetchedDevices = await getThings();
        setSites(fetchedSites);
        setDeviceTypes(fetchedDeviceTypes);
        setDevices(fetchedDevices);
      } catch (err) {
        setError('Failed to fetch data. Please try again later.');
        console.error('Error fetching data:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredDevices = useMemo(() => {
    return devices.filter(device => 
      (selectedTypes.length === 0 || selectedTypes.includes(device.thingType)) &&
      Object.values(device).some(value => 
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [devices, selectedTypes, searchTerm]);

  const sortedDevices = useMemo(() => {
    let sortableItems = [...filteredDevices];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [filteredDevices, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleTypeSelect = (type) => {
    if (!selectedTypes.includes(type)) {
      setSelectedTypes([...selectedTypes, type]);
    }
  };

  const handleTypeRemove = (type) => {
    setSelectedTypes(selectedTypes.filter(t => t !== type));
  };

  const handleRowClick = (deviceId) => {
    navigate(`/devices/${deviceId}`);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="w-full min-h-screen bg-gray-100 p-4">
      <h1 className="text-3xl font-bold mb-6">Devices</h1>
      
      <div className="mb-6">
        <h2 className="text-xl mb-4">Summary</h2>
        <div className="flex space-x-4">
          <div className="bg-white p-4 rounded-lg shadow-md flex-1">
            <div className="text-4xl font-bold">{devices.length}</div>
            <div className="text-gray-500">Devices</div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md flex-1">
            <div className="text-4xl font-bold">{deviceTypes.length}</div>
            <div className="text-gray-500">Device Types</div>
          </div>
        </div>
      </div>
      
      <div className="mb-6 flex items-center space-x-4">
        <div className="relative flex-1">
          <input
            type="text"
            placeholder="Search"
            className="w-full pl-10 pr-4 py-2 border rounded-md"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <svg className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        </div>
      </div>
      
      <div className="mb-6 flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <div className="text-lg font-semibold">Devices</div>
          <div className="text-gray-500">{sortedDevices.length}</div>
          {selectedTypes.map(type => (
            <button
              key={type}
              className="bg-gray-200 px-3 py-1 rounded-full text-sm flex items-center"
              onClick={() => handleTypeRemove(type)}
            >
              {type}
              <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          ))}
        </div>
        <div className="relative">
          <select
            className="appearance-none bg-white border px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            onChange={(e) => handleTypeSelect(e.target.value)}
            value=""
          >
            <option value="" disabled>Category: Multiple</option>
            {deviceTypes.map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
            </svg>
          </div>
        </div>
      </div>
      
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left cursor-pointer" onClick={() => requestSort('thingType')}>
                Device Type {sortConfig.key === 'thingType' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
              </th>
              <th className="py-3 px-6 text-left cursor-pointer" onClick={() => requestSort('healthScore')}>
                Health Score {sortConfig.key === 'healthScore' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
              </th>
              <th className="py-3 px-6 text-left">Device ID</th>
              <th className="py-3 px-6 text-left">Update Status</th>
              <th className="py-3 px-6 text-left">Location</th>
              <th className="py-3 px-6 text-left">Asset Age</th>
              <th className="py-3 px-6 text-left">Lifecycle Stage</th>
              <th className="py-3 px-6 text-left">Mobility</th>
              <th className="py-3 px-6 text-left">Connectivity</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {sortedDevices.map((device, index) => {
              const site = sites.find(s => s.siteId === device.siteId);
              return (
                <tr 
                  key={index} 
                  className="border-b border-gray-200 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleRowClick(device.thingName)}
                >
                  <td className="py-3 px-6 text-left whitespace-nowrap">{device.thingType}</td>
                  <td className="py-3 px-6 text-left">{device.healthScore || 'N/A'}%</td>
                  <td className="py-3 px-6 text-left">{device.thingName}</td>
                  <td className="py-3 px-6 text-left">{device.updateStatus || 'Unknown'}</td>
                  <td className="py-3 px-6 text-left">{site ? `${site.latitude}, ${site.longitude}` : 'N/A'}</td>
                  <td className="py-3 px-6 text-left">{device.assetAge || 'Unknown'}</td>
                  <td className="py-3 px-6 text-left">{device.lifecycleStage || 'Unknown'}</td>
                  <td className="py-3 px-6 text-left">{device.mobility || (device.thingType === 'Station' ? 'Stationary' : 'Mobile')}</td>
                  <td className="py-3 px-6 text-left">{device.connectivity || 'Unknown'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DeviceList;