import { usePlacesApi } from "api/ingestion/places";
import { FleetResponse, SiteResponse } from "api/ingestion/places";
import { useThingsApi } from "api/ingestion/things";

import React, { useEffect, useState } from "react";

type Place = {
  id: string;
  name: string;
  type: "site" | "fleet";
};

const AddDeviceModal = ({ isOpen, onClose, onDeviceAdded, deviceTypes }) => {
  const [places, setPlaces] = useState<Place[]>([]);
  const [formData, setFormData] = useState({
    thingName: "",
    thingType: deviceTypes[0],
    thingDescription: "",
    thingManufacturerId: "",
    primaryGroup: "",
    model: "",
    isSimulated: false,
    longitude: "",
    latitude: "",
    altitude: "",
    levelId: "",
    placeId: "",
    placeType: "",
  });

  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const { addDevice } = useThingsApi();
  const { getSites, getFleets } = usePlacesApi();

  useEffect(() => {
    const fetchPlaces = async () => {
      const [sites, fleets] = await Promise.all([getSites(), getFleets()]);
      const combinedPlaces: Place[] = [
        ...sites.map((site) => ({
          id: site.siteId,
          name: site.siteName,
          type: "site",
        })),
        ...fleets.map((fleet) => ({
          id: fleet.fleetId,
          name: fleet.fleetName,
          type: "fleet",
        })),
      ];
      setPlaces(combinedPlaces);
    };

    fetchPlaces();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "placeId") {
      const selectedPlace = places.find((place) => place.id === value);
      setFormData((prev) => ({
        ...prev,
        placeId: value,
        placeType: selectedPlace?.type || "",
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const validateForm = () => {
    let valid = true;
    let errors = {};

    // Required fields for all devices
    if (!formData.thingName) {
      errors.thingName = "Thing Name is required";
      valid = false;
    }
    if (!formData.thingType) {
      errors.thingType = "Thing Type is required";
      valid = false;
    }
    if (!formData.thingDescription) {
      errors.thingDescription = "Description is required";
      valid = false;
    }
    if (!formData.placeId) {
      errors.placeId = "Place is required";
      valid = false;
    }
    if (formData.latitude === "") {
      errors.latitude = "Latitude is required";
      valid = false;
    }
    if (formData.longitude === "") {
      errors.longitude = "Longitude is required";
      valid = false;
    }

    // Level ID is only required for sites
    if (formData.placeType === "site" && !formData.levelId) {
      errors.levelId = "Level ID is required for site devices";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const renderLevelIdField = () => {
    if (formData.placeType === "site") {
      return (
        <div>
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="levelId"
          >
            Level ID *
          </label>
          <input
            type="text"
            name="levelId"
            id="levelId"
            value={formData.levelId}
            onChange={handleChange}
            className={`border rounded w-full py-2 px-3 text-gray-700 ${errors.levelId ? "border-red-500" : ""}`}
          />
          {errors.levelId && (
            <p className="text-red-500 text-xs italic">{errors.levelId}</p>
          )}
        </div>
      );
    }
    return null;
  };

  const handleAddDevice = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    // Create a copy of formData and remove levelId if it's a fleet device
    const deviceData = { ...formData };
    if (deviceData.placeType === "fleet") {
      delete deviceData.levelId;
    }

    try {
      const result = await addDevice(
        formData.placeType,
        formData.placeId,
        deviceData,
      );
      if (result.success) {
        onDeviceAdded();
        handleCloseModal();
      } else {
        setErrorMessage(
          result.error?.message ||
            (typeof result.error === "string"
              ? result.error
              : "Failed to add device. Please try again."),
        );

        if (result.error?.errors) {
          setErrors((prev) => ({
            ...prev,
            ...result.error.errors,
          }));
        }
      }
    } catch (error) {
      setErrorMessage(
        error?.message || "An unexpected error occurred. Please try again.",
      );
    }
  };

  const handleCloseModal = () => {
    onClose();
    setFormData({
      thingName: "",
      thingType: deviceTypes[0],
      thingDescription: "",
      thingManufacturerId: "",
      primaryGroup: "",
      model: "",
      isSimulated: false,
      longitude: "",
      latitude: "",
      altitude: "",
      levelId: "",
      placeId: "",
      placeType: "",
    });
    setErrors({});
    setErrorMessage("");
  };

  return isOpen ? (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
      <div className="bg-white p-6 rounded-lg shadow-lg w-[800px]">
        <h2 className="text-xl mb-4">Add Device</h2>
        <form onSubmit={handleAddDevice}>
          <div className="grid grid-cols-3 gap-4 mb-4">
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="placeId"
              >
                Place *
              </label>
              <select
                name="placeId"
                id="placeId"
                value={formData.placeId}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 ${errors.placeId ? "border-red-500" : ""}`}
              >
                <option value="">Select a place</option>
                <optgroup label="Sites">
                  {places
                    .filter((p) => p.type === "site")
                    .map((place) => (
                      <option key={place.id} value={place.id}>
                        {place.name}
                      </option>
                    ))}
                </optgroup>
                <optgroup label="Fleets">
                  {places
                    .filter((p) => p.type === "fleet")
                    .map((place) => (
                      <option key={place.id} value={place.id}>
                        {place.name}
                      </option>
                    ))}
                </optgroup>
              </select>
              {errors.placeId && (
                <p className="text-red-500 text-xs italic">{errors.placeId}</p>
              )}
            </div>

            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="thingType"
              >
                Type *
              </label>
              <select
                name="thingType"
                value={formData.thingType}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 ${errors.thingType ? "border-red-500" : ""}`}
              >
                {deviceTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
              {errors.thingType && (
                <p className="text-red-500 text-xs italic">
                  {errors.thingType}
                </p>
              )}
            </div>

            {renderLevelIdField()}
          </div>

          <div className="grid grid-cols-3 gap-4 mb-4">
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="thingName"
              >
                Name
              </label>
              <input
                type="text"
                name="thingName"
                id="thingName"
                value={formData.thingName}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 ${errors.thingName ? "border-red-500" : ""}`}
              />
              {errors.thingName && (
                <p className="text-red-500 text-xs italic">
                  {errors.thingName}
                </p>
              )}
            </div>

            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="thingManufacturerId"
              >
                Manufacturer ID
              </label>
              <input
                type="text"
                name="thingManufacturerId"
                id="thingManufacturerId"
                value={formData.thingManufacturerId}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 ${errors.thingManufacturerId ? "border-red-500" : ""}`}
              />
              {errors.thingManufacturerId && (
                <p className="text-red-500 text-xs italic">
                  {errors.thingManufacturerId}
                </p>
              )}
            </div>

            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="primaryGroup"
              >
                Primary Group
              </label>
              <input
                type="text"
                name="primaryGroup"
                id="primaryGroup"
                value={formData.primaryGroup}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 ${errors.primaryGroup ? "border-red-500" : ""}`}
              />
              {errors.primaryGroup && (
                <p className="text-red-500 text-xs italic">
                  {errors.primaryGroup}
                </p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="thingDescription"
              >
                Description *
              </label>
              <input
                type="text"
                name="thingDescription"
                id="thingDescription"
                value={formData.thingDescription}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 ${errors.thingDescription ? "border-red-500" : ""}`}
              />
              {errors.thingDescription && (
                <p className="text-red-500 text-xs italic">
                  {errors.thingDescription}
                </p>
              )}
            </div>

            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="model"
              >
                Model
              </label>
              <input
                type="text"
                name="model"
                id="model"
                value={formData.model}
                onChange={handleChange}
                className="border rounded w-full py-2 px-3 text-gray-700"
              />
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4 mb-4">
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="latitude"
              >
                Latitude *
              </label>
              <input
                type="number"
                name="latitude"
                id="latitude"
                value={formData.latitude}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 ${errors.latitude ? "border-red-500" : ""}`}
              />
              {errors.latitude && (
                <p className="text-red-500 text-xs italic">{errors.latitude}</p>
              )}
            </div>

            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="longitude"
              >
                Longitude *
              </label>
              <input
                type="number"
                name="longitude"
                id="longitude"
                value={formData.longitude}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 ${errors.longitude ? "border-red-500" : ""}`}
              />
              {errors.longitude && (
                <p className="text-red-500 text-xs italic">
                  {errors.longitude}
                </p>
              )}
            </div>

            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="altitude"
              >
                Altitude
              </label>
              <input
                type="number"
                name="altitude"
                id="altitude"
                value={formData.altitude}
                onChange={handleChange}
                className="border rounded w-full py-2 px-3 text-gray-700"
              />
            </div>
          </div>

          <div className="mb-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="isSimulated"
                id="isSimulated"
                checked={formData.isSimulated}
                onChange={handleChange}
                className="form-checkbox h-5 w-5 text-gray-600"
              />
              <span className="ml-2 text-gray-700 text-sm font-bold">
                Simulated
              </span>
            </label>
          </div>

          {errorMessage && (
            <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md">
              <p className="text-red-600 text-sm">{errorMessage}</p>
            </div>
          )}

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 rounded-full border border-space80 text-space70"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 rounded-full border border-space80 bg-blue40 text-white"
            >
              Add Device
            </button>
          </div>
        </form>
      </div>
    </div>
  ) : null;
};

export default AddDeviceModal;
