import {
  DatapointMap,
  Timeseries,
  convertToTimeseries,
  datapointsToMap,
  useDataApi,
} from "api/data";
import { Thing } from "api/ingestion/things";
import { useSelectedDevice } from "context/SelectedDeviceContext.tsx";
import { useSelectedSimulation } from "context/SelectedSimulationContext.tsx";
import { useSelectedTimeRange } from "context/SelectedTimeRangeContext.tsx";
import { typeToLabel } from "utils/typeToLabel";

import { useEffect, useState } from "react";

import { ReactComponent as BatteriesIcon } from "images/icons/batteries.svg";
import { ReactComponent as ChargersIcon } from "images/icons/chargers.svg";
import { ReactComponent as MeterIcon } from "images/icons/meter.svg";

import { useAuth } from "../../context/AuthContext";

enum SupportedDeviceType {
  Charger,
  Meter,
  Battery,
  SwapStation,
}
const getDeviceType = (thingType: string): SupportedDeviceType => {
  if (thingType == "Charger") {
    return SupportedDeviceType.Charger;
  } else if (thingType == "Meter") {
    return SupportedDeviceType.Meter;
  } else if (thingType == "Battery") {
    return SupportedDeviceType.Battery;
  } else if (thingType == "SwapStation") {
    return SupportedDeviceType.SwapStation;
  } else {
    throw new Error("Unsupported device type");
  }
};

enum Color {
  Blue = "blue",
  Space = "space",
  Green = "green",
  Yellow = "yellow",
  Red = "red",
  Gray = "gray",
  Low = "low",
  Medium = "medium",
  High = "high",
}

const colorToTailwindText = (color: Color) => {
  switch (color) {
    case Color.Blue:
      return "text-blue20";
    case Color.Space:
      return "text-space20";
    case Color.Green:
      return "text-green20";
    case Color.Yellow:
      return "text-yellow20";
    case Color.Red:
      return "text-red20";
    case Color.Gray:
      return "text-gray20";
    case Color.Low:
      return "text-blue10";
    case Color.Medium:
      return "text-blue10";
    case Color.High:
      return "text-blue10";
  }
};

const colorToTailwindBackground = (color: Color) => {
  switch (color) {
    case Color.Blue:
      return "bg-blue80";
    case Color.Space:
      return "bg-space80";
    case Color.Green:
      return "bg-green80";
    case Color.Yellow:
      return "bg-yellow80";
    case Color.Red:
      return "bg-red80";
    case Color.Gray:
      return "bg-gray97";
    case Color.Low:
      return "bg-blue90";
    case Color.Medium:
      return "bg-blue80";
    case Color.High:
      return "bg-blue70";
  }
};

const percentageToColor = (percentage: number) => {
  if (isNaN(percentage)) {
    return Color.Gray;
  }

  if (percentage < 0.2) {
    return Color.Low;
  } else if (percentage < 0.5) {
    return Color.Medium;
  } else {
    return Color.High;
  }
};

export const ProgressBar = ({
  value,
  percentage,
  unit,
  label,
  colorOverride = null,
  digits = 0,
}: {
  value: number;
  percentage: number;
  unit: string;
  label: string;
  colorOverride?: Color | null;
  digits?: number;
}) => {
  const formattedValue = value.toLocaleString("en-US", {
    maximumFractionDigits: digits,
  });

  const color = colorOverride ?? percentageToColor(percentage);

  // HACK: make the tailwind compiler to recognize that we need to generate these colors
  const backgroundColor = colorToTailwindBackground(color);
  const textColor = colorToTailwindText(color);

  return (
    <div className="relative w-full">
      <div className="z-0 absolute top-0 left-0 bottom-0 right-0">
        <div
          className={`h-full ${backgroundColor}`}
          style={{ width: `${Math.min(percentage, 1) * 100}%` }}
        ></div>
      </div>
      <div className="z-10 relative px-4 py-1 justify-between items-center flex">
        <div className={`${textColor} justify-start items-center gap-1 flex`}>
          <div className="text-body">{formattedValue}</div>
          <div className="text-[10px]">{unit}</div>
        </div>
        <div className="text-right text-[10px] capitalize">
          {label}
        </div>
      </div>
    </div>
  );
};

const Icon = ({ device }: { device: Thing }) => {
  switch (device.thingType) {
    case "Charger":
      return <ChargersIcon />;
    case "Battery":
      return <BatteriesIcon />;
    case "Meter":
      return <MeterIcon />;
    default:
      return null;
  }
};

const DeviceTile = ({ device }: { device: Thing }) => {
  const { selectedDevice, setSelectedDevice } = useSelectedDevice();
  const { start, end } = useSelectedTimeRange();
  const [stats, setStats] = useState<DatapointMap>({});
  const { user } = useAuth();

  const { simulationId } = useSelectedSimulation();
  const { getSummaryForThing } = useDataApi();

  useEffect(() => {
    if (!user) return;
    getSummaryForThing(
      device.placeType ?? "site",
      device.siteId,
      device.thingId,
      start,
      end,
      simulationId,
    )
      .then(datapointsToMap)
      .then(setStats);
  }, [device, start, end, user?.partnerId]);

  const header = (
    <div className="flex w-full px-2 pt-2 justify-between items-center">
      <div className="flex gap-2 justify-start items-center">
        <Icon device={device} />
        <div className="shrink">
          <div className="text-footnote text-space50 break-words whitespace-normal max-w-[120px]">
            {device.thingName}
          </div>
          <div className="text-detail text-space70 break-words whitespace-normal max-w-[120px]">
            {device.thingDescription}
          </div>
        </div>
      </div>
      {/* Status? */}
      {/* Alerts */}
    </div>
  );

  // TODO: figure out %
  const statsView =
    Object.values(stats).length > 0 ? (
      <>
        {Object.values(stats).map((stat) => (
          <ProgressBar
            key={stat.type}
            value={stat.value ?? "-"}
            percentage={stat.value / 100}
            unit={stat.unit}
            label={typeToLabel(stat.type)}
          />
        ))}
      </>
    ) : (
      <ProgressBar value={"-"} percentage={0} unit="" label="No data" />
    );

  return (
    <div
      className={`bg-white rounded-md elevation-1 cursor-pointer hover:-translate-y-0.5 focus:translate-y-0.5 transition-colors transition-transform transition-shadow hover:shadow-md w-[160px] flex-col justify-start items-start gap-2 flex ${selectedDevice === device ? "outline outline-2 outline-offset-2 outline-blue50" : ""}`}
      onClick={() => {
        setSelectedDevice(selectedDevice === device ? null : device);
      }}
    >
      {header}
      <div className="w-full pb-2 flex-col justify-start items-start gap-px flex">
        {stats && statsView}
      </div>
    </div>
  );
};

export default DeviceTile;
