import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AddSiteModal = ({ isOpen, onClose, onAddSite }) => {
  const [formData, setFormData] = useState({
    siteName: '',
    address: '',
    latitude: '',
    longitude: '',
  });

  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let newErrors = {};

    if (!formData.siteName.trim()) {
      newErrors.siteName = 'Site Name is required';
    }
    if (!formData.address.trim()) {
      newErrors.address = 'Address is required';
    }
    if (!formData.latitude) {
      newErrors.latitude = 'Latitude is required';
    } else if (isNaN(formData.latitude) || formData.latitude < -90 || formData.latitude > 90) {
      newErrors.latitude = 'Latitude must be a number between -90 and 90';
    }
    if (!formData.longitude) {
      newErrors.longitude = 'Longitude is required';
    } else if (isNaN(formData.longitude) || formData.longitude < -180 || formData.longitude > 180) {
      newErrors.longitude = 'Longitude must be a number between -180 and 180';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddSite = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const result = await onAddSite(formData);
      if (result.success) {
        setFormData({
          siteName: '',
          address: '',
          latitude: '',
          longitude: '',
        });
        setErrors({});
        setErrorMessage('');
        onClose();
        // Redirect to the new site's page
        const siteId = JSON.parse(result.siteId).replace(/^"(.*)"$/, '$1')
        navigate(`/sites/${siteId}`);
      } else {
        setErrorMessage(result.error.message || "An error occurred while adding the site.");
      }
    } catch (error) {
      console.error("Error adding site:", error);
      setErrorMessage("An unexpected error occurred. Please try again.");
    }
  };
  

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed z-20 inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="flex items-center justify-center min-h-screen p-4">
        <div 
          className="fixed inset-0 transition-opacity" 
          aria-hidden="true"
          onClick={onClose}
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>

        <div 
          className="bg-white p-8 rounded-lg w-full max-w-2xl relative z-50"
          role="dialog" 
          aria-modal="true" 
          aria-labelledby="modal-headline"
        >
          <h2 className="text-2xl font-bold mb-6" id="modal-headline">Add Site</h2>
          <form onSubmit={handleAddSite}>
            <div className="grid grid-cols-2 gap-6">
              <div className="col-span-2">
                <label htmlFor="siteName" className="block text-sm font-medium text-gray-700 mb-1">Site Name</label>
                <input
                  type="text"
                  id="siteName"
                  name="siteName"
                  value={formData.siteName}
                  onChange={handleChange}
                  className={`w-full border rounded-md px-3 py-2 ${errors.siteName ? 'border-red-500' : 'border-gray-300'}`}
                />
                {errors.siteName && <p className="text-red-500 text-xs mt-1">{errors.siteName}</p>}
              </div>
              <div>
                <label htmlFor="longitude" className="block text-sm font-medium text-gray-700 mb-1">Longitude</label>
                <input
                  type="number"
                  id="longitude"
                  name="longitude"
                  value={formData.longitude}
                  onChange={handleChange}
                  step="any"
                  className={`w-full border rounded-md px-3 py-2 ${errors.longitude ? 'border-red-500' : 'border-gray-300'}`}
                />
                {errors.longitude && <p className="text-red-500 text-xs mt-1">{errors.longitude}</p>}
              </div>
              <div>
                <label htmlFor="latitude" className="block text-sm font-medium text-gray-700 mb-1">Latitude</label>
                <input
                  type="number"
                  id="latitude"
                  name="latitude"
                  value={formData.latitude}
                  onChange={handleChange}
                  step="any"
                  className={`w-full border rounded-md px-3 py-2 ${errors.latitude ? 'border-red-500' : 'border-gray-300'}`}
                />
                {errors.latitude && <p className="text-red-500 text-xs mt-1">{errors.latitude}</p>}
              </div>
              <div className="col-span-2">
                <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">Address</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className={`w-full border rounded-md px-3 py-2 ${errors.address ? 'border-red-500' : 'border-gray-300'}`}
                />
                {errors.address && <p className="text-red-500 text-xs mt-1">{errors.address}</p>}
              </div>
            </div>
            
            {errorMessage && (
              <p className="text-red-500 text-sm mt-4 mb-4">
                {errorMessage}
              </p>
            )}
            
            <div className="mt-8 space-y-4">
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 rounded-md border border-gray-300 text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 rounded-md border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Add Site
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddSiteModal;