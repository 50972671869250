import { Invite, useIdentityApi } from "api/ingestion/identity";
import { useAuth } from "context/AuthContext";

import React, { useEffect, useState } from "react";
import { FaClipboard, FaTrash } from "react-icons/fa";

import { DeleteInviteModal } from "./deleteInviteModal";

const InviteRow = ({
  invite,
  handleOpenDeleteModal,
  editable,
}: {
  invite: Invite;
  handleOpenDeleteModal: (invite: Invite) => void;
  editable: boolean;
}) => {
  return (
    <tr key={invite.id}>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0 font-medium text-space10">
        {invite.invitee.email}
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        <button
          className="text-blue50 hover:text-blue20 underline"
          onClick={() => navigator.clipboard.writeText(invite.invitationUrl)}
        >
          <FaClipboard />
          <span className="sr-only">
            Copy invite for {invite.invitee.email}
          </span>
        </button>
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        {new Date(invite.createdAt).toLocaleString()}
      </td>
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
        {new Date(invite.expiresAt).toLocaleString()}
      </td>
      {editable && (
        <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
          <a
            href="#"
            onClick={() => handleOpenDeleteModal(invite)}
            className="text-red50 hover:text-red20"
          >
            <FaTrash />
            <span className="sr-only">Delete, {invite.invitee.email}</span>
          </a>
        </td>
      )}
    </tr>
  );
};
const InvitesTable = ({
  invites,
  refresh,
  editable,
}: {
  invites: Invite[] | null;
  refresh: () => Promise<void>;
  editable: boolean;
}) => {
  const [inviteToDelete, setInviteToDelete] = useState<Invite | null>(null);

  if (!invites)
    return <div className="text-center text-space50 pt-4">Loading...</div>;

  const handleCloseDeleteModal = () => {
    setInviteToDelete(null);
    refresh();
  };

  return (
    <div className="overflow-x-auto">
      <DeleteInviteModal
        invite={inviteToDelete}
        open={inviteToDelete !== null}
        setOpen={handleCloseDeleteModal}
      />
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-space10 sm:pl-0"
            >
              Email
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-space10 sm:pl-0"
            >
              Invitation Link
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-space10 sm:pl-0"
            >
              Created
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-space10 sm:pl-0"
            >
              Expires
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
              <span className="sr-only">Edit</span>
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
              <span className="sr-only">Delete</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {invites.length === 0 && (
            <tr>
              <td colSpan={6} className="text-center text-space50 pt-4">
                No invites found
              </td>
            </tr>
          )}
          {invites.map((invite) => (
            <InviteRow
              key={invite.id}
              invite={invite}
              handleOpenDeleteModal={setInviteToDelete}
              editable={editable}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const InvitesSection = ({ refreshKey }: { refreshKey: boolean }) => {
  const { getInvites } = useIdentityApi();
  const { permissions } = useAuth();

  const writeAuthorized = permissions.includes("write:ingest_admin");

  const [invites, setInvites] = useState<Invite[] | null>(null);

  const fetchAllInvites = async () => {
    let allInvites: Invite[] = [];
    let page = 0;
    let pageSize = 50;
    let fetchedInvites: Invite[] = [];

    do {
      fetchedInvites = await getInvites(page, pageSize);
      allInvites = [...allInvites, ...fetchedInvites];
      page++;
    } while (fetchedInvites.length === pageSize);
    setInvites(allInvites);
  };

  useEffect(() => {
    fetchAllInvites();
  }, [refreshKey.toString()]);

  return (
    <div>
      <div className="flex justify-between">
        <div className="text-heading1 text-space50 flex items-center">
          Invitations
        </div>
      </div>
      <InvitesTable
        invites={invites}
        refresh={fetchAllInvites}
        editable={writeAuthorized}
      />
    </div>
  );
};
