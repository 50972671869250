import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useIngestionApi } from 'api';
import { useAuth } from "context/AuthContext";

const ScopeSelection = () => {
  const { providerName } = useParams();
  console.log(providerName)
  const location = useLocation()
  const [params, setParams] = useState({})
  const [integrationId, setIntegrationId] = useState('');
  const { user } = useAuth();

  const { thirdPartyFlow } = useIngestionApi();

  useEffect(() => {
    if (providerName.toLowerCase() === 'sce') {
      const queryParams = new URLSearchParams(location.search)
      const cisrId = queryParams.get('cisrId')
      const scope = queryParams.get('scope')
      const cleanScope = scope?.split('#')[0]
    if (!user) return;

      const parameters = {
        cisrId,
        scope: cleanScope,
      }
      console.log(parameters)
      setParams(parameters)
      triggerThirdPartyFlow(parameters, providerName, user.partnerId);
    }
  }, [location, providerName, user.partnerId]);

  const triggerThirdPartyFlow = async (parameters, providerName, partnerId) => {
    const integrationId = await thirdPartyFlow(
      parameters,
      providerName,
      partnerId,
    );
    setIntegrationId(integrationId);
  };

  const redirectToSCE = () => {
    const client_id = 'SCE_client_id'
    const redirect_uri = 'https://spectra.aerovy.com/mock_response_page'
    const response_type = 'code'
    const state = integrationId
    const authUrl = `https://wwwsysb1.sce.com/wps/myportal/home/mysce/datasharing/authorizeavendor?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${params.scope}&state=${state}&cisrId=${params.cisrId}`

    window.location.href = authUrl;
  };

  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-gray-100'>
      <div className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4'>
        <h2 className='text-gray-700 text-xl font-bold mb-2'>{`${providerName.toUpperCase()} Scope Selection Confirmation`}</h2>
        {/* Display the parsed scope and cisrId for confirmation */}
        <p className='mb-4'>CISR ID: {params.cisrId}</p>
        <p className='mb-6'>Scope: {params.scope}</p>
        <p className='mb-6'>Site/Scope Selection/Confirmaition: {integrationId}</p>
        <button onClick={redirectToSCE} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md'>
          Proceed to SCE Authorization
        </button>
      </div>
    </div>
  );
};

export default ScopeSelection;
