import React, { useState, useEffect } from 'react';
import { useIngestionApi } from "api";
// import { useNavigate } from 'react-router-dom';

const ThirdParty = () => {
  const [selectedProvider, setSelectedProvider] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [showCustomerIdInput, setShowCustomerIdInput] = useState(false);
  const [sites, setSites] = useState([]);
  const [selectedSiteId, setSelectedSiteId] = useState('');
  const [error, setError] = useState('');

  const { postIntegration, getSites } = useIngestionApi();

  useEffect(() => {
    if (selectedProvider === 'SCE') { // In future might filter to SCE only sites or pull all sites and filter locally
      getSites().then(setSites).catch(err => setError('Failed to load sites.'));
    }
  }, [selectedProvider]);

  const handleProviderSelection = (e) => {
    setSelectedProvider(e.target.value);
    setShowCustomerIdInput(e.target.value === 'SCE');
    if (e.target.value !== 'SCE') {
      setSelectedSiteId('');
    }
  };

  const handleSiteSelectionChange = (siteId) => {
    setSelectedSiteId(siteId);
  };

  const initializeIntegration = async () => {
    const { success, error } = await postIntegration(selectedSiteId, customerId);
    if (!success) {
      setError(error);
    } else {
      setError('');
    }
  }

  const initiateRedirect = async () => {
    if (selectedProvider === 'SCE' && customerId) {
      try {
        await initializeIntegration()
        const sceAuthUrl = `https://wwwsysb1.sce.com/wps/myportal/home/mysce/datasharing/authorizeavendor?thirdPartyId=${"SCE_thirdpartyid"}`
        window.location.href = sceAuthUrl
      } catch (error) { }
    }
  };

  return (
    <div className="max-w-md mx-auto my-10">
      <h2 className="text-xl font-semibold mb-4">Authorize Third Party</h2>

      {showCustomerIdInput &&
        <>
          <div className="mb-4">
            <div className="text-gray-700 text-sm font-bold mb-2">Select Site:</div>
            {sites.length > 0 ?
              <div className="space-y-2">
                {sites.map(site => (
                  <div key={site.siteId} className="flex items-center">
                    <input
                      type="radio"
                      name="siteSelection"
                      checked={selectedSiteId === site.siteId}
                      onChange={() => handleSiteSelectionChange(site.siteId)}
                      className="mr-2"
                    />
                    <label>{site.siteName}</label>
                  </div>
                ))}
              </div>
              :
              <p>No sites available to select.</p>
            }
          </div>
        </>
      }

      <div className="mb-4">
        <div className="text-gray-700 text-sm font-bold mb-2">Choose a provider:</div>
        <select
          value={selectedProvider}
          onChange={handleProviderSelection}
          className="w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded-md shadow-md"
        >
          <option value="">--Please choose an option--</option>
          <option value="SCE">Southern California Edison (SCE)</option>
          {/* More Providers */}
        </select>
      </div>
      {showCustomerIdInput &&
        <div className="mb-4">
          <div className="text-gray-700 text-sm font-bold mb-2">Enter Your Customer ID:</div>
          <input
            type="text"
            value={customerId}
            onChange={(e) => setCustomerId(e.target.value)}
            className="w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded-md shadow-md"
          />
        </div>
      }
      <div>
        <button
          onClick={initiateRedirect}
          disabled={!customerId}
          className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${!customerId ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          Authorize Provider
        </button>
      </div>

      {error &&
        <div className="mt-4 text-red-500">
          {error}
        </div>
      }
    </div>
  );
};

export default ThirdParty;
