import { useEnterpriseApi } from "api/enterprise";
import { useSelectedDevice } from "context/SelectedDeviceContext.tsx";

import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as BatteriesIcon } from "images/icons/batteries.svg";
import { ReactComponent as LockIcon } from "images/icons/lock.svg";
import { ReactComponent as UnlockIcon } from "images/icons/unlock.svg";

type BatteryInfo = {
  thingId: string;
  thingManufacturerId: string;
  soc: number;
  soh: number;
  batteryState: string;
};

type StationBoxState = {
  stationBoxId: string;
  stationBoxState: string;
  stationBoxIsCharging: boolean;
  stationBoxDoorState: string;
  batteryManufacturerId: string | null;
  batteryInfo?: BatteryInfo;
};

type SwapStationData = {
  stationState: string;
  stationBoxStates: StationBoxState[];
  batteriesAvailable: number;
  fullBatteriesAvailable: number;
  batteryManufacturerIds: string[];
  manufacturerId: string;
  lastEventTime: string;
};

type Transaction = {
  boxId: string;
  transactionId: string;
  recordId: string;
  type: "rent" | "return" | "swap";
};

type SwapSelection = {
  returnBox: StationBoxState;
  fullBatteryBox: StationBoxState | null;
};

type DoorStatus = {
  doorId: string;
  isOpening: boolean;
  success: boolean | null;
  timestamp: number;
};

interface BoxInterfaceProps {
  box: StationBoxState;
  activeTransaction: Transaction | null;
  handleRentBattery: (box: StationBoxState) => void;
  handleReturnBattery: (box: StationBoxState) => void;
  handleSwapSelection: (box: StationBoxState) => void;
  handleOpenDoor: (boxId: string) => Promise<void>;
  isProcessing: boolean;
  isSelected: boolean;
  doorStatus: DoorStatus | undefined;
}

const formatTimeWithDiff = (timestamp: string) => {
  // Parse the UTC timestamp correctly
  const date = new Date(timestamp + "Z"); // Append 'Z' to ensure it's parsed as UTC
  const now = new Date();

  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  let timeAgo;
  if (diffInSeconds < 60) {
    timeAgo = `${diffInSeconds} seconds ago`;
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    timeAgo = `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    timeAgo = `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
  } else {
    const days = Math.floor(diffInSeconds / 86400);
    timeAgo = `${days} ${days === 1 ? "day" : "days"} ago`;
  }

  return {
    formatted: date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    }),
    timeAgo,
  };
};

const BoxInterface: React.FC<BoxInterfaceProps> = ({
  box,
  activeTransaction,
  handleRentBattery,
  handleReturnBattery,
  handleSwapSelection,
  handleOpenDoor,
  isProcessing,
  isSelected,
  doorStatus,
}) => {
  const isOpening = doorStatus?.isOpening;
  const showSuccess =
    doorStatus?.success && Date.now() - doorStatus.timestamp < 3000;

  const getBgColor = () => {
    if (isSelected) return "bg-blue-50 border-blue-200";
    if (box.stationBoxState === "FullCharge")
      return "bg-emerald-50 border-emerald-200";
    if (box.stationBoxState === "NoBattery")
      return "bg-gray-50 border-gray-200";
    return "bg-white border-gray-200";
  };

  return (
    <div
      className={`h-36 p-4 rounded-xl border ${getBgColor()} shadow-sm transition-all duration-200 hover:shadow-md flex flex-col`}
    >
      <div className="flex items-center justify-between mb-3">
        <div className="flex items-center gap-2">
          <span className="text-footnote font-semibold text-gray-800">
            Box {box.stationBoxId}
          </span>
          <button
            onClick={() =>
              box.stationBoxDoorState === "Closed" &&
              handleOpenDoor(box.stationBoxId)
            }
            disabled={isProcessing || isOpening}
            className={`p-1 hover:bg-gray-100 rounded-full transition-colors ${isProcessing || isOpening ? "opacity-50" : ""}`}
            title="Click to open door"
          >
            {isOpening ? (
              <span className="inline-block w-4 h-4 border-2 border-gray-400 border-t-transparent rounded-full animate-spin"></span>
            ) : box.stationBoxDoorState === "Closed" ? (
              <LockIcon />
            ) : (
              <UnlockIcon />
            )}
          </button>
        </div>
        <span
          className={`px-2 py-1 rounded-full text-detail font-medium ${
            box.stationBoxDoorState === "Closed"
              ? "bg-green-100 text-green-800"
              : "bg-red-100 text-red-800"
          }`}
        >
          {box.stationBoxDoorState}
        </span>
      </div>

      <div className="mb-4">
        <div className="flex items-center gap-2">
          {/* Charging Indicator */}
          <span
            className={`inline-block w-2 h-2 rounded-full ${
              box.stationBoxIsCharging
                ? "bg-green-500 animate-pulse"
                : "bg-gray-400"
            }`}
          ></span>

          {/* Battery Info - Only show for boxes with battery */}
          {box.batteryManufacturerId && box.batteryInfo ? (
            <>
              <div className="flex gap-2 justify-start items-center bg-gray-800 rounded-md text-white text-detail px-2 py-1">
                <BatteriesIcon />
                <span>{box.batteryManufacturerId.slice(-5)}</span>
              </div>
              <div className="flex items-center">
                <span className="text-detail font-semibold text-gray-700">
                  {box.batteryInfo.soc}
                </span>
                <span className="text-gray-400">/</span>
                <span className="text-detail font-semibold text-gray-700">
                  {box.batteryInfo.soh}%
                </span>
              </div>
            </>
          ) : (
            /* Show state text for boxes without battery */
            <span className="text-detail font-medium text-gray-700">
              {box.stationBoxState}
            </span>
          )}
        </div>
      </div>

      {!activeTransaction && (
        <div className="mt-auto flex flex-col gap-2">
          {box.stationBoxState === "NoBattery" && (
            <div className="flex gap-2">
              <button
                onClick={() => handleReturnBattery(box)}
                disabled={isProcessing || box.stationBoxDoorState !== "Closed"}
                className="flex-1 px-3 py-1.5 bg-gray-500 text-white text-detail rounded-lg hover:bg-gray-600 disabled:opacity-50 transition-colors duration-200"
              >
                Return
              </button>
              <button
                onClick={() => handleSwapSelection(box)}
                disabled={isProcessing || box.stationBoxDoorState !== "Closed"}
                className="flex-1 px-3 py-1.5 bg-emerald-500 text-white text-detail rounded-lg hover:bg-emerald-600 disabled:opacity-50 transition-colors duration-200"
              >
                Swap
              </button>
            </div>
          )}
          {box.batteryManufacturerId &&
            box.stationBoxState === "FullCharge" && (
              <button
                onClick={() => handleRentBattery(box)}
                disabled={isProcessing || box.stationBoxDoorState !== "Closed"}
                className="px-3 py-1.5 bg-blue-500 text-white text-detail rounded-lg hover:bg-blue-600 disabled:opacity-50 transition-colors duration-200"
              >
                Rent
              </button>
            )}
        </div>
      )}

      {activeTransaction?.boxId === box.stationBoxId && (
        <div className="mt-2 py-2 px-3 bg-blue-50 text-blue-700 rounded-lg text-detail font-medium">
          {activeTransaction.type} - Processing...
        </div>
      )}
    </div>
  );
};

const ControlsTab: React.FC<{
  placeType: string;
  siteId: string;
  thingId: string;
  thingManufacturerId: string;
}> = ({ placeType, siteId, thingId, thingManufacturerId }) => {
  const { selectedDevice } = useSelectedDevice();
  const [stationData, setStationData] = useState<SwapStationData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [activeTransaction, setActiveTransaction] =
    useState<Transaction | null>(null);
  const [swapSelection, setSwapSelection] = useState<SwapSelection | null>(
    null,
  );
  const [batteryNumber, setBatteryNumber] = useState("");
  const [showReturnInput, setShowReturnInput] = useState(false);
  const [selectedReturnBox, setSelectedReturnBox] =
    useState<StationBoxState | null>(null);
  const [selectedDoors, setSelectedDoors] = useState<string[]>([]);
  const [showDoorSelector, setShowDoorSelector] = useState(false);
  const [doorStatuses, setDoorStatuses] = useState<Record<string, DoorStatus>>(
    {},
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const {
    getSwapStationData,
    getSwapStationBatteries,
    rentBattery: apiRentBattery,
    returnBattery: apiReturnBattery,
    swapBattery: apiSwapBattery,
    checkTransactionStatus,
    confirmControl,
    rebootSwapStation,
    refreshSwapStation,
    openDoors: apiOpenDoors,
    openAllDoors: apiOpenAllDoors,
  } = useEnterpriseApi();

  const partnerId = "5fe49f4b-ad65-4863-a221-f2664f06e335";
  const TRANSACTION_TIMEOUT = 40000;
  const PULL_STATUS_INTERVAL = 2000;
  const REFRESH_BOX_STATUS_INTERVAL = 5000;

  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Device validation effect
  useEffect(() => {
    if (selectedDevice?.thingId !== thingId) {
      console.error("ThingId mismatch between selected device and props");
      return;
    }
  }, [selectedDevice, thingId]);

  // Main data fetching logic
  const fetchData = useCallback(async () => {
    if (isLoading || activeTransaction) return;

    try {
      setIsLoading(true);
      const stationData = await getSwapStationData(partnerId, thingId, siteId);
      const batteryData = await getSwapStationBatteries(
        partnerId,
        thingId,
        siteId,
      );

      const sortedBoxes = stationData.stationBoxStates
        .map((box) => {
          if (box.batteryManufacturerId) {
            const batteryInfo = batteryData.find(
              (b) => b.thingManufacturerId === box.batteryManufacturerId,
            );
            return { ...box, batteryInfo };
          }
          return box;
        })
        .sort((a, b) => {
          const aNum = parseInt(a.stationBoxId);
          const bNum = parseInt(b.stationBoxId);
          const aCol = Math.ceil(aNum / 4) - 1;
          const bCol = Math.ceil(bNum / 4) - 1;
          const aRow = (aNum - 1) % 4;
          const bRow = (bNum - 1) % 4;
          return aCol !== bCol ? aCol - bCol : aRow - bRow;
        });

      setStationData({ ...stationData, stationBoxStates: sortedBoxes });
      setError(null);
      setIsInitialized(true);

      if (!activeTransaction) {
        timeoutRef.current = setTimeout(fetchData, REFRESH_BOX_STATUS_INTERVAL);
      }
    } catch (err) {
      console.error("Failed to fetch data:", err);
      setError("Failed to fetch station data");

      if (!activeTransaction) {
        timeoutRef.current = setTimeout(fetchData, REFRESH_BOX_STATUS_INTERVAL);
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    partnerId,
    thingId,
    siteId,
    getSwapStationData,
    getSwapStationBatteries,
    activeTransaction,
    isLoading,
  ]);

  // Cleanup effect
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    // Clear existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    // Reset initialization state when thingId changes
    setIsInitialized(false);
    setStationData(null);
  }, [thingId]);

  // Transaction handling effect
  useEffect(() => {
    if (!activeTransaction) return;

    const timeoutId = setTimeout(() => {
      setActiveTransaction(null);
      setIsProcessing(false);
      alert(
        `Battery ${activeTransaction.type} operation timed out after ${TRANSACTION_TIMEOUT / 1000} seconds`,
      );
    }, TRANSACTION_TIMEOUT);

    const pollStatus = async () => {
      try {
        const status = await checkTransactionStatus(
          partnerId,
          thingId,
          siteId,
          activeTransaction.transactionId,
        );

        const acceptedEvent = status.events?.find(
          (event) => event.status === "Accepted",
        );
        if (acceptedEvent) {
          try {
            const confirmResponse = await confirmControl(
              activeTransaction.type,
              activeTransaction.transactionId,
              activeTransaction.recordId,
              partnerId,
              thingManufacturerId,
              thingId,
              siteId,
            );

            if (confirmResponse?.msgType === 500) {
              clearTimeout(timeoutId);
              setActiveTransaction(null);
              setIsProcessing(false);
              fetchData();
              alert(`Battery ${activeTransaction.type} completed successfully`);
              return;
            }
          } catch (err) {
            console.error("Error confirming transaction:", err);
          }
        }

        if (
          status.transactionStatus === "COMPLETED" ||
          status.transactionStatus === "FAILED"
        ) {
          clearTimeout(timeoutId);
          setActiveTransaction(null);
          setIsProcessing(false);
          fetchData();
          alert(
            `Battery ${activeTransaction.type} ${status.transactionStatus.toLowerCase()}`,
          );
          return;
        }
      } catch (err) {
        console.error("Error checking transaction status:", err);
      }
    };

    const pollInterval = setInterval(pollStatus, PULL_STATUS_INTERVAL);

    return () => {
      clearInterval(pollInterval);
      clearTimeout(timeoutId);
    };
  }, [
    activeTransaction,
    partnerId,
    thingId,
    siteId,
    thingManufacturerId,
    checkTransactionStatus,
    confirmControl,
    fetchData,
    PULL_STATUS_INTERVAL,
  ]);
  useEffect(() => {
    // Clear existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    // Reset initialization state when thingId changes
    setIsInitialized(false);
    setStationData(null);
  }, [thingId]);

  // Door status handler
  const updateDoorStatus = (
    doorId: string,
    isOpening: boolean,
    success: boolean | null = null,
  ) => {
    setDoorStatuses((prev) => ({
      ...prev,
      [doorId]: {
        doorId,
        isOpening,
        success,
        timestamp: Date.now(),
      },
    }));
  };

  // Battery operation handlers
  const handleRentBattery = async (box: StationBoxState) => {
    if (!box.batteryManufacturerId) return;
    setIsProcessing(true);

    try {
      const response = await apiRentBattery(
        partnerId,
        thingId,
        thingManufacturerId,
        siteId,
        box.stationBoxId,
        box.batteryManufacturerId,
      );

      setActiveTransaction({
        boxId: box.stationBoxId,
        transactionId: response.transactionId,
        recordId: response.paramList[0].recordId,
        type: "rent",
      });
    } catch (err) {
      console.error(err);
      alert("Failed to rent battery");
      setIsProcessing(false);
    }
  };

  const handleReturnBattery = (box: StationBoxState) => {
    setSwapSelection(null); // Close swap selection if open
    setSelectedReturnBox(box);
    setShowReturnInput(true);
  };

  const handleSwapSelection = (box: StationBoxState) => {
    setShowReturnInput(false); // Close return input if open
    setSelectedReturnBox(null);
    setBatteryNumber(""); // Clear battery number

    const fullChargeBox = stationData?.stationBoxStates.find(
      (b) => b.stationBoxState === "FullCharge",
    );

    if (!fullChargeBox) {
      alert("No fully charged batteries available");
      return;
    }

    if (box.stationBoxState === "NoBattery") {
      setSwapSelection({
        returnBox: box,
        fullBatteryBox: fullChargeBox,
      });
    } else {
      alert("Please select an empty box for returning battery");
    }
  };

  // Transaction confirmation handlers
  const handleConfirmReturn = async () => {
    if (!selectedReturnBox || batteryNumber.length !== 5) return;
    setIsProcessing(true);

    try {
      const batteryId = `BT207203012HBDJ2408${batteryNumber}`;
      const response = await apiReturnBattery(
        partnerId,
        thingId,
        thingManufacturerId,
        siteId,
        selectedReturnBox.stationBoxId,
        batteryId,
      );

      setActiveTransaction({
        boxId: selectedReturnBox.stationBoxId,
        transactionId: response.transactionId,
        recordId: response.paramList[0].recordId,
        type: "return",
      });

      setShowReturnInput(false);
      setBatteryNumber("");
      setSelectedReturnBox(null);
    } catch (err) {
      console.error(err);
      alert("Failed to return battery");
      setIsProcessing(false);
    }
  };

  const handleConfirmSwap = async () => {
    if (
      !swapSelection?.returnBox ||
      !swapSelection.fullBatteryBox ||
      batteryNumber.length !== 5
    )
      return;
    setIsProcessing(true);

    try {
      const emptyBatteryId = `BT207203012HBDJ2408${batteryNumber}`;
      const response = await apiSwapBattery(
        partnerId,
        thingManufacturerId,
        thingId,
        siteId,
        swapSelection.returnBox.stationBoxId,
        emptyBatteryId,
        swapSelection.fullBatteryBox.batteryManufacturerId!,
        swapSelection.fullBatteryBox.stationBoxId,
      );

      setActiveTransaction({
        boxId: swapSelection.returnBox.stationBoxId,
        transactionId: response.transactionId,
        recordId: response.paramList[0].recordId,
        type: "swap",
      });

      setBatteryNumber("");
      setSwapSelection(null);
    } catch (err) {
      console.error(err);
      alert("Failed to initiate battery swap");
      setIsProcessing(false);
      setSwapSelection(null);
    }
  };

  // Station control handlers
  const handleReboot = async () => {
    if (!window.confirm("Are you sure you want to reboot the swap station?"))
      return;

    try {
      await rebootSwapStation(partnerId, thingId, thingManufacturerId, siteId);
      alert("Reboot command sent successfully");
    } catch (err) {
      console.error(err);
      alert("Failed to reboot swap station");
    }
  };

  const handleRefresh = async () => {
    try {
      await refreshSwapStation(partnerId, thingId, thingManufacturerId, siteId);
      alert("Refresh command sent successfully");
    } catch (err) {
      console.error(err);
      alert("Failed to refresh data");
    }
  };

  // Door control handlers
  const handleOpenDoor = async (doorId: string) => {
    updateDoorStatus(doorId, true);
    try {
      await apiOpenDoors(partnerId, thingId, thingManufacturerId, siteId, [
        doorId,
      ]);
      updateDoorStatus(doorId, false, true);
      alert("Door opened successfully");
    } catch (err) {
      console.error(err);
      updateDoorStatus(doorId, false, false);
      alert("Failed to open door");
    }
  };

  const handleOpenSelectedDoors = async () => {
    if (selectedDoors.length === 0) return;

    selectedDoors.forEach((doorId) => updateDoorStatus(doorId, true));

    try {
      await apiOpenDoors(
        partnerId,
        thingId,
        thingManufacturerId,
        siteId,
        selectedDoors,
      );

      selectedDoors.forEach((doorId) => {
        updateDoorStatus(doorId, false, true);
        setTimeout(() => updateDoorStatus(doorId, false, null), 3000);
      });

      setShowDoorSelector(false);
      setSelectedDoors([]);
    } catch (err) {
      console.error(err);
      selectedDoors.forEach((doorId) => updateDoorStatus(doorId, false, false));
      alert("Failed to open selected doors");
    }
  };

  const handleOpenAllDoors = async () => {
    if (!window.confirm("Are you sure you want to open all doors?")) return;

    const allDoorIds = stationData!.stationBoxStates.map(
      (box) => box.stationBoxId,
    );
    allDoorIds.forEach((doorId) => updateDoorStatus(doorId, true));

    try {
      const maxDoorId = Math.max(...allDoorIds.map((id) => parseInt(id)));
      await apiOpenAllDoors(
        partnerId,
        thingId,
        thingManufacturerId,
        siteId,
        maxDoorId,
      );

      allDoorIds.forEach((doorId) => {
        updateDoorStatus(doorId, false, true);
        setTimeout(() => updateDoorStatus(doorId, false, null), 3000);
      });
    } catch (err) {
      console.error(err);
      allDoorIds.forEach((doorId) => updateDoorStatus(doorId, false, false));
      alert("Failed to open all doors");
    }
  };

  const toggleDoorSelection = (doorId: string) => {
    setSelectedDoors((prev) =>
      prev.includes(doorId)
        ? prev.filter((id) => id !== doorId)
        : [...prev, doorId],
    );
  };

  if (error) return <div className="p-4 text-red-500">{error}</div>;

  if (!isInitialized) {
    return (
      <div className="flex flex-col items-center justify-center p-8 space-y-4">
        <div className="text-footnote text-gray-600">
          Click to load station data
        </div>
        <button
          onClick={fetchData}
          disabled={isLoading}
          className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50 flex items-center gap-2"
        >
          {isLoading ? (
            <>
              <span className="inline-block w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></span>
              <span>Loading...</span>
            </>
          ) : (
            "Load Station Data"
          )}
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      {/* Control Buttons */}
      <div className="flex gap-3">
        <button
          onClick={() => {
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
              timeoutRef.current = null;
            }
            fetchData();
          }}
          disabled={isLoading}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50 text-footnote"
        >
          {isLoading ? "Refresh..." : "Refresh"}
        </button>
        <button
          onClick={handleRefresh}
          className="px-4 py-2 bg-emerald-500 text-white rounded-lg hover:bg-emerald-600 text-footnote"
        >
          Sync Cloud
        </button>
        <button
          onClick={handleReboot}
          className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 text-footnote"
        >
          Reboot Station
        </button>
        <button
          onClick={() => setShowDoorSelector(!showDoorSelector)}
          className="px-4 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 text-footnote"
        >
          Open Doors
        </button>
      </div>

      {/* Stats and Summary */}
      {stationData && (
        <div className="p-4 bg-white rounded-xl shadow-sm border">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div>
              <div className="text-detail text-space70">Total Batteries</div>
              <div className="text-footnote font-medium">
                {stationData.batteriesAvailable}
              </div>
            </div>
            <div>
              <div className="text-detail text-space70">Full Batteries</div>
              <div className="text-footnote font-medium">
                {stationData.fullBatteriesAvailable}
              </div>
            </div>
            <div>
              <div className="text-detail text-space70">Station State</div>
              <div className="text-footnote font-medium">
                {stationData.stationState}
              </div>
            </div>
            <div>
              <div className="text-detail text-space70">Manufacturer ID</div>
              <div className="text-footnote font-medium truncate">
                {thingManufacturerId}
              </div>
            </div>
          </div>

          {/* Timestamps */}
          <div className="mt-4 border-t pt-4">
            <div className="flex items-center gap-2">
              <div className="text-detail text-space70">Last Event Time:</div>
              <div className="text-footnote font-medium">
                {formatTimeWithDiff(stationData.lastEventTime).formatted}
                {" ("}
                {formatTimeWithDiff(stationData.lastEventTime).timeAgo}
                {")"}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Door Selector */}
      {showDoorSelector && stationData && (
        <div className="p-4 bg-white rounded-xl shadow-sm border">
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-footnote font-medium">Select Doors to Open</h4>
            <div className="flex gap-2">
              <button
                onClick={() =>
                  setSelectedDoors(
                    stationData.stationBoxStates.map((box) => box.stationBoxId),
                  )
                }
                className="px-3 py-1 text-detail text-blue-600 hover:text-blue-800"
              >
                Select All
              </button>
              <button
                onClick={() => setSelectedDoors([])}
                className="px-3 py-1 text-detail text-gray-600 hover:text-gray-800"
              >
                Deselect All
              </button>
              <button
                onClick={() => setShowDoorSelector(false)}
                className="px-3 py-1 text-detail text-gray-600 hover:text-gray-800 rounded border border-gray-300"
              >
                Cancel
              </button>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4">
            {[0, 4, 8].map((startIdx) => (
              <div key={startIdx} className="space-y-4">
                {stationData.stationBoxStates
                  .slice(startIdx, startIdx + 4)
                  .map((box) => (
                    <label
                      key={box.stationBoxId}
                      className="flex items-center gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={selectedDoors.includes(box.stationBoxId)}
                        onChange={() => toggleDoorSelection(box.stationBoxId)}
                        className="rounded"
                      />
                      <span className="text-detail">
                        Box {box.stationBoxId}
                      </span>
                    </label>
                  ))}
              </div>
            ))}
          </div>

          <button
            onClick={handleOpenSelectedDoors}
            disabled={selectedDoors.length === 0}
            className="w-full mt-4 px-4 py-2 bg-amber-500 text-white rounded-lg disabled:opacity-50 text-footnote"
          >
            Open Selected Doors
          </button>
        </div>
      )}

      {/* Return Input */}
      {showReturnInput && (
        <div className="p-4 bg-white rounded-xl shadow-sm border">
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-footnote font-medium">
              Enter Battery Number for Return
            </h4>
            <button
              onClick={() => {
                setShowReturnInput(false);
                setBatteryNumber("");
                setSelectedReturnBox(null);
              }}
              className="px-3 py-1 text-detail text-gray-600 hover:text-gray-800 rounded border border-gray-300"
            >
              Cancel
            </button>
          </div>
          <div className="flex gap-2">
            <input
              type="text"
              value={batteryNumber}
              onChange={(e) => setBatteryNumber(e.target.value.slice(0, 5))}
              placeholder="Enter 5-digit battery number"
              className="flex-1 px-3 py-2 border rounded text-detail"
              maxLength={5}
            />
            <button
              onClick={handleConfirmReturn}
              disabled={batteryNumber.length !== 5 || isProcessing}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:opacity-50 text-footnote"
            >
              Confirm Return
            </button>
          </div>
        </div>
      )}

      {/* Swap Selection */}
      {swapSelection && (
        <div className="p-4 bg-white rounded-xl shadow-sm border">
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-footnote font-medium">Battery Swap</h4>
            <button
              onClick={() => {
                setSwapSelection(null);
                setBatteryNumber("");
              }}
              className="px-3 py-1 text-detail text-gray-600 hover:text-gray-800 rounded border border-gray-300"
            >
              Cancel
            </button>
          </div>

          <div className="mb-4">
            <p className="text-detail text-gray-600 mb-2">
              Return Box: #{swapSelection.returnBox.stationBoxId}
            </p>
            <p className="text-detail text-gray-600">
              Full Battery Box: #{swapSelection.fullBatteryBox?.stationBoxId}
              {" - "}
              {swapSelection.fullBatteryBox?.batteryManufacturerId}
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <input
              type="text"
              value={batteryNumber}
              onChange={(e) => setBatteryNumber(e.target.value.slice(0, 5))}
              placeholder="Enter 5-digit battery number to return"
              className="px-3 py-2 border rounded text-detail"
              maxLength={5}
            />
            <button
              onClick={handleConfirmSwap}
              disabled={isProcessing || batteryNumber.length !== 5}
              className="w-full px-4 py-2 bg-blue-500 text-white rounded-lg disabled:opacity-50 text-footnote"
            >
              {isProcessing ? "Processing..." : "Confirm Swap"}
            </button>
          </div>
        </div>
      )}

      {/* Grid Layout for Battery Boxes */}
      {stationData && (
        <div className="grid grid-cols-3 gap-4">
          {[0, 4, 8].map((startIdx) => (
            <div key={startIdx} className="space-y-4">
              {stationData.stationBoxStates
                .slice(startIdx, startIdx + 4)
                .map((box) => (
                  <BoxInterface
                    key={box.stationBoxId}
                    box={box}
                    activeTransaction={activeTransaction}
                    handleRentBattery={handleRentBattery}
                    handleReturnBattery={handleReturnBattery}
                    handleSwapSelection={handleSwapSelection}
                    handleOpenDoor={handleOpenDoor}
                    isProcessing={isProcessing}
                    isSelected={
                      swapSelection?.returnBox === box ||
                      swapSelection?.fullBatteryBox === box
                    }
                    doorStatus={doorStatuses[box.stationBoxId]}
                  />
                ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ControlsTab;
