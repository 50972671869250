import { datapointsToMap, useDataApi } from "api/data";
import { Thing } from "api/ingestion/things.ts";
import { ProgressBar } from "components/devices/DeviceTile";
import { useSelectedDevice } from "context/SelectedDeviceContext.tsx";
import { useSelectedSimulation } from "context/SelectedSimulationContext.tsx";
import { useSelectedTimeRange } from "context/SelectedTimeRangeContext.tsx";

import React, { useEffect, useMemo, useState } from "react";

import { ReactComponent as BatteriesIcon } from "images/icons/batteries.svg";
import { ReactComponent as ChargersIcon } from "images/icons/chargers.svg";
import { ReactComponent as MeterIcon } from "images/icons/meter.svg";

const DevicesTable = ({ devices }: { devices: Thing[] }) => {
  const { selectedDevice, setSelectedDevice } = useSelectedDevice();
  const { start, end } = useSelectedTimeRange();
  const [filteredDevices, setFilteredDevices] = useState(devices);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const deviceTypes = useMemo(() => {
    return Array.from(new Set(devices.map((device) => device.thingType)));
  }, [devices]);

  useEffect(() => {
    const result =
      selectedTypes.length > 0
        ? devices.filter((device) => selectedTypes.includes(device.thingType))
        : devices;
    setFilteredDevices(result);
  }, [devices, selectedTypes]);

  const sortedDevices = useMemo(() => {
    let sortableDevices = [...filteredDevices];
    if (sortConfig.key !== null) {
      sortableDevices.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableDevices;
  }, [filteredDevices, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleRowClick = (device: Thing) => {
    setSelectedDevice(selectedDevice === device ? null : device);
  };

  const handleTypeChange = (type) => {
    setSelectedTypes((prev) =>
      prev.includes(type) ? prev.filter((t) => t !== type) : [...prev, type],
    );
  };

  const getDeviceIcon = (deviceType: string) => {
    switch (deviceType) {
      case "Battery":
        return <BatteriesIcon />;
      case "Charger":
        return <ChargersIcon />;
      case "Meter":
        return <MeterIcon />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="mb-4 flex space-x-4">
        {deviceTypes.map((type) => (
          <label key={type} className="inline-flex items-center">
            <input
              type="checkbox"
              checked={selectedTypes.includes(type)}
              onChange={() => handleTypeChange(type)}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2 text-gray-700">{type}</span>
          </label>
        ))}
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              className="px-3 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer w-24"
              onClick={() => requestSort("thingType")}
            >
              Type {sortConfig.key === "thingType" && (sortConfig.direction === "ascending" ? "▲" : "▼")}
            </th>
            <th
              className="px-3 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer w-24"
              onClick={() => requestSort("placeType")}
            >
              Place Type {sortConfig.key === "placeType" && (sortConfig.direction === "ascending" ? "▲" : "▼")}
            </th>
            <th
              className="px-3 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer w-40"
              onClick={() => requestSort("thingName")}
            >
              Name {sortConfig.key === "thingName" && (sortConfig.direction === "ascending" ? "▲" : "▼")}
            </th>
            <th
              className="px-3 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer w-48"
              onClick={() => requestSort("thingDescription")}
            >
              Description {sortConfig.key === "thingDescription" && (sortConfig.direction === "ascending" ? "▲" : "▼")}
            </th>
            <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">Data</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {sortedDevices.map((device) => (
            <tr
              key={device.thingId}
              onClick={() => handleRowClick(device)}
              className={`cursor-pointer hover:bg-gray-100 ${
                selectedDevice === device ? "bg-blue-100" : ""
              }`}
            >
              <td className="px-3 py-4 whitespace-nowrap">
                <div className="flex items-center">
                  {getDeviceIcon(device.thingType)}
                  <span className="ml-2 text-sm">{device.thingType}</span>
                </div>
              </td>
              <td className="px-3 py-4 whitespace-nowrap text-sm">
                {device.placeType || (device.fleetId ? 'fleet' : 'site')}
              </td>
              <td className="px-3 py-4 whitespace-nowrap text-sm">
                {device.thingName}
              </td>
              <td className="px-3 py-4 whitespace-nowrap text-sm overflow-hidden overflow-ellipsis">
                {device.thingDescription}
              </td>
              <td className="px-3 py-4">
                <DeviceStats device={device} start={start} end={end} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const DeviceStats = ({ device, start, end }) => {
  const [stats, setStats] = useState<DatapointMap>(null);
  const { simulationId } = useSelectedSimulation();
  const { getSummaryForThing } = useDataApi();

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const placeType = device.placeType || (device.fleetId ? 'fleet' : 'site');
        const placeId = device.fleetId || device.siteId;
        
        getSummaryForThing(
          placeType,
          placeId,
          device.thingId,
          start,
          end,
          simulationId,
        )
          .then(datapointsToMap)
          .then(setStats)
          .catch(console.error);
      } catch (error) {
        console.error("Error fetching device stats:", error);
        setStats({});
      }
    };

    fetchStats();
  }, [device, start, end, simulationId, getSummaryForThing]);

  if (stats === null) {
    return <div>Loading...</div>;
  }

  const renderProgressBar = (
    value,
    percentage,
    label,
    unit,
    colorOverride = null,
  ) => (
    <div className="flex items-center space-x-2">
      <span className="text-xs w-20">{label}</span>
      <ProgressBar
        value={value}
        percentage={percentage}
        unit={unit}
        label=""
        colorOverride={colorOverride}
      />
    </div>
  );

  switch (device.thingType) {
    case "Meter":
      return (
        <div className="flex space-x-4">
          {renderProgressBar(
            stats.fwd?.value ?? 0,
            (stats.fwd?.value ?? 0) / 1500,
            "Forward",
            "kWh",
          )}
          {renderProgressBar(
            stats.reverse?.value ?? 0,
            (stats.reverse?.value ?? 0) / 1500,
            "Reverse",
            "kWh",
          )}
          {renderProgressBar(
            stats.net?.value ?? 0,
            (stats.net?.value ?? 0) / 1500,
            "Net",
            "kWh",
          )}
        </div>
      );
    case "Charger":
      return (
        <div className="flex space-x-4">
          {renderProgressBar(
            stats.discharged?.value ?? 0,
            0.798,
            "Discharged",
            "kWh",
          )}
          {renderProgressBar(
            stats.scheduled?.value ?? 0,
            0.798,
            "Scheduled",
            "kWh",
          )}
          {renderProgressBar(
            stats.possible?.value ?? 0,
            1,
            "Possible",
            "kWh",
            "Gray",
          )}
          {renderProgressBar(stats.fwd?.value ?? 0, 0.798, "Drawn", "kWh")}
        </div>
      );
    case "Battery":
      return (
        <div className="flex space-x-4">
          {renderProgressBar(
            stats.stored?.value ?? 0,
            (stats.stored?.value ?? 0) / (stats.capacity?.value ?? 1),
            "Stored",
            "kWh",
          )}
          {renderProgressBar(
            stats.health?.value ?? 0,
            (stats.health?.value ?? 0) / 100,
            "Health",
            "% SoH",
          )}
          {renderProgressBar(
            stats.capacity?.value ?? 0,
            1,
            "Capacity",
            "kWh",
            "Gray",
          )}
        </div>
      );
    default:
      return <div>No stats available for this device type</div>;
  }
};

export default DevicesTable;
