import { Pin } from "components";
import { mapboxConfig } from "configs/mapbox";

import Map, { Marker } from "react-map-gl";

export const MapView = ({ locations }) => {
  const average = (arr) =>
    arr.length ? arr.reduce((a, b) => a + b, 0) / arr.length : 0;

  const avgLat = average(locations.map((location) => location.latitude));
  const avgLong = average(locations.map((location) => location.longitude));

  if (isNaN(avgLat) || isNaN(avgLong)) {
    return null;
  }

  return (
    <Map
      key={`${avgLat}-${avgLong}`}
      initialViewState={{
        longitude: avgLong,
        latitude: avgLat,
        zoom: 2,
      }}
      mapStyle="mapbox://styles/mapbox/light-v11"
      mapboxAccessToken={mapboxConfig.token}
    >
      {locations.map((location, index) => (
        <Marker
          key={`location-${index}`}
          longitude={location.longitude}
          latitude={location.latitude}
          anchor="center"
          className="flex flex-row"
        >
          <Pin color={"#6db5d1"} id={index + 1} />
        </Marker>
      ))}
    </Map>
  );
};
